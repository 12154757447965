@charset "UTF-8";
* {
  line-break: strict; }

.bm_entry-01 .bm_entry-title {
  background-color: #e9ecef;
  padding: 1rem;
  margin-bottom: 0;
  text-align: center; }

.bm_entry-01 .bm_entry-body {
  background-color: #f8f9fa;
  margin-bottom: 1rem;
  padding: 1rem; }

.bm_entry-02 .bm_entry-title {
  background-color: #e9ecef;
  padding: 1rem;
  margin-bottom: 0;
  text-align: center; }

.bm_entry-02 .bm_entry-body {
  background-color: #f8f9fa;
  margin-bottom: 1rem;
  padding: 1rem; }

.bm_entry-03 .bm_entry-list li {
  margin-bottom: 1rem; }

.bm_entry-03 .bm_entry-title {
  font-size: 1.25rem; }

.bm_entry-03 .bm_entry-body {
  margin-bottom: 1rem; }

.bm_entry-04 .bm_entry-list li {
  margin-bottom: 1rem; }

.bm_entry-04 .bm_entry-title {
  font-size: 1.25rem; }

.bm_entry-04 .bm_entry-body {
  margin-bottom: 1rem; }

section {
  overflow: hidden; }

.dlb_firstview-center {
  background-position: 50% 50%; }
  .dlb_firstview-center .container {
    padding-top: 48px;
    padding-bottom: 48px; }

.dlb_firstview-left {
  background-position: 50% 50%; }
  .dlb_firstview-left .container {
    padding-top: 48px;
    padding-bottom: 48px; }

.dlb_firstview-right {
  background-position: 50% 50%; }
  .dlb_firstview-right .container {
    padding-top: 48px;
    padding-bottom: 48px; }

.dlb_firstview-items .dlb_container-text {
  padding-top: 3rem;
  padding-bottom: 11.5rem; }

.dlb_firstview-items .dlb_container-items {
  margin-top: -10rem; }
  @media (max-width: 767.98px) {
    .dlb_firstview-items .dlb_container-items .col {
      padding-left: 2rem;
      padding-right: 2rem;
      min-width: 300px;
      height: 85vw;
      margin-top: 2rem; }
      .dlb_firstview-items .dlb_container-items .col:first-child {
        margin-top: 0; }
      .dlb_firstview-items .dlb_container-items .col img {
        width: 80px;
        height: auto; } }
  @media (min-width: 576px) {
    .dlb_firstview-items .dlb_container-items .col {
      height: 350px; } }
  @media (min-width: 768px) {
    .dlb_firstview-items .dlb_container-items .col {
      height: 350px; } }
  @media (min-width: 992px) {
    .dlb_firstview-items .dlb_container-items .col {
      height: 300px; } }
  @media (min-width: 1200px) {
    .dlb_firstview-items .dlb_container-items .col {
      height: 350px; } }

.dlb_firstview-text {
  overflow: visible;
  position: relative; }
  @media (min-width: 768px) {
    .dlb_firstview-text {
      margin-top: 106px;
      margin-bottom: 48px; } }
  @media (min-width: 768px) {
    .dlb_firstview-text {
      margin-top: 130px;
      margin-bottom: 72px; } }
  .dlb_firstview-text:before {
    content: '';
    width: 60%;
    height: 360px;
    background-color: #ddd;
    position: absolute;
    top: calc(50% - 180px);
    left: 0;
    z-index: -2; }
    @media (max-width: 991.98px) {
      .dlb_firstview-text:before {
        width: 55%; } }
    @media (max-width: 767.98px) {
      .dlb_firstview-text:before {
        width: 80%; } }
  .dlb_firstview-text .bg-img {
    background-position: 50% 50%;
    width: 60%;
    height: 360px;
    background-color: #f5f4f2;
    position: absolute;
    top: calc(50% - 230px);
    right: 0;
    z-index: -1; }
    @media (max-width: 991.98px) {
      .dlb_firstview-text .bg-img {
        width: 55%; } }
    @media (max-width: 767.98px) {
      .dlb_firstview-text .bg-img {
        position: relative;
        width: 100%;
        height: 240px;
        top: 0;
        margin-left: 15px;
        margin-right: 15px; } }

.dlb_title-h1 {
  font-weight: bold; }

.dlb_title-h2 {
  font-weight: bold; }

.dlb_title-h3 {
  font-weight: bold; }

.dlb_title-h4 {
  font-weight: bold; }

.dlb_title-h5 {
  font-weight: bold; }

.dlb_title-h6 {
  font-weight: bold; }

.dlb_text .dlb_text-copy {
  line-height: 1; }

@media (min-width: 576px) {
  .dlb_text_wrap .container {
    max-width: 510px; } }

@media (min-width: 768px) {
  .dlb_text_wrap .container {
    max-width: 690px; } }

@media (min-width: 992px) {
  .dlb_text_wrap .container {
    max-width: 930px; } }

@media (min-width: 1200px) {
  .dlb_text_wrap .container {
    max-width: 1120px; } }

.dlb_text_wrap .dlb_text_wrap-title {
  position: relative;
  margin-top: -44px;
  bottom: 24px;
  line-height: 1.7; }
  @media (max-width: 767.98px) {
    .dlb_text_wrap .dlb_text_wrap-title {
      margin-top: -28px;
      bottom: 12px; } }
  .dlb_text_wrap .dlb_text_wrap-title * {
    display: inline;
    padding: 8px;
    line-height: 1.5; }

.dlb_text_wrap p:last-child {
  margin-bottom: 0; }

.dlb_contents_float figure {
  max-width: 80%; }

@media (min-width: 576px) {
  .dlb_contents_float figcaption {
    max-width: 290px; } }

.dlb_contents_bg_position-right .dlb_contents_bg_text {
  position: relative;
  z-index: 1; }
  @media (max-width: 991.98px) {
    .dlb_contents_bg_position-right .dlb_contents_bg_text {
      position: static; } }

.dlb_contents_bg_position-right .dlb_contents_bg_image {
  margin-right: auto; }
  @media (min-width: 992px) {
    .dlb_contents_bg_position-right .dlb_contents_bg_image {
      margin-left: -12rem; } }

.dlb_contents_bg_position-left .row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse; }

.dlb_contents_bg_position-left .dlb_contents_bg_text {
  position: relative;
  z-index: 1; }
  @media (max-width: 991.98px) {
    .dlb_contents_bg_position-left .dlb_contents_bg_text {
      position: static; } }

.dlb_contents_bg_position-left .dlb_contents_bg_image {
  margin-left: auto; }
  @media (min-width: 992px) {
    .dlb_contents_bg_position-left .dlb_contents_bg_image {
      margin-right: -12rem; } }

.dlb_contents_wrap-wide {
  background-position: center center;
  background-repeat: no-repeat; }
  .dlb_contents_wrap-wide .dlb_contents_wrap-text {
    padding-top: 24px;
    padding-bottom: 24px;
    background: rgba(0, 0, 0, 0.3); }
  @media (max-width: 991.98px) {
    .dlb_contents_wrap-wide .row {
      padding-left: 15px;
      padding-right: 15px; } }

.dlb_contents_wrap-double .dlb_contents_wrap-title {
  padding-top: 48px;
  padding-bottom: 48px; }

.dlb_contents_wrap-double .dlb_contents_wrap-data {
  margin-top: -10rem;
  border-radius: 20px; }
  .dlb_contents_wrap-double .dlb_contents_wrap-data .accodion-link {
    position: relative;
    border: none;
    outline: none;
    background-color: transparent;
    background-position: 0 0;
    background-repeat: no-repeat;
    width: 100%;
    text-align: left;
    margin: 20px 0 6px;
    padding: 0 0 0 40px;
    color: #333; }
    .dlb_contents_wrap-double .dlb_contents_wrap-data .accodion-link:hover, .dlb_contents_wrap-double .dlb_contents_wrap-data .accodion-link:active, .dlb_contents_wrap-double .dlb_contents_wrap-data .accodion-link:focus {
      outline: none; }
    .dlb_contents_wrap-double .dlb_contents_wrap-data .accodion-link::before {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      display: inline-block;
      width: 24px;
      height: 24px;
      background-color: #333;
      border: 1px solid #333;
      -webkit-transition: 0.3s ease;
      transition: 0.3s ease;
      border-radius: 50%;
      vertical-align: bottom; }
    .dlb_contents_wrap-double .dlb_contents_wrap-data .accodion-link:after {
      content: "";
      position: relative;
      top: 50%;
      margin-top: 2px;
      display: inline-block;
      width: 8px;
      height: 8px;
      min-width: 8px;
      margin-right: 0.5rem;
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      position: absolute;
      top: 11px;
      left: 8px;
      margin-right: 0;
      -webkit-transition: 0.3s ease;
      transition: 0.3s ease;
      -webkit-transform-origin: center center 0;
      transform-origin: center center 0; }
    .dlb_contents_wrap-double .dlb_contents_wrap-data .accodion-link.collapsed::before {
      background-color: #fff;
      border: 1px solid #333; }
    .dlb_contents_wrap-double .dlb_contents_wrap-data .accodion-link.collapsed::after {
      -webkit-transform: rotate(-225deg);
      transform: rotate(-225deg);
      top: 8px;
      left: 8px;
      border-color: #333; }

.dlb_contents_modal_column-2 .modal-btn a {
  position: relative;
  padding-right: 2rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }
  .dlb_contents_modal_column-2 .modal-btn a::after {
    content: '+';
    display: block;
    position: absolute;
    right: 10px;
    top: 0;
    font-size: 1.5rem;
    color: inherit; }

.dlb_contents_modal_column-2 .modal::before {
  content: '×';
  width: 48px;
  height: 48px;
  display: block;
  background: #000;
  color: #fff;
  line-height: 42px;
  text-align: center;
  font-size: 2rem;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.3s ease; }

.dlb_contents_modal_column-2 .modal.show::before {
  opacity: 1; }

@media (min-width: 768px) {
  .modal-dialog {
    max-width: 980px;
    margin: 1.75rem auto; } }

.dlb_contents_info .container {
  margin-top: 1rem;
  margin-bottom: 1rem; }

.dlb_contents_info .dlb_contents_info-item {
  transition: transform 0.3s ease; }
  .dlb_contents_info .dlb_contents_info-item:hover {
    transform: scale(1.08); }

.dlb_contents_info .row {
  margin: 0 -10px; }
  .dlb_contents_info .row > * {
    padding: 0 10px; }

@media (max-width: 767.98px) {
  .dlb_contents_info .h4 {
    font-size: 0.725rem; }
  .dlb_contents_info .dlb_title-h3 {
    font-size: 1rem; } }

.dlb_contents_qa .dlb_contents_qa-title {
  max-width: 65px !important;
  padding-right: 0; }
  @media (max-width: 767.98px) {
    .dlb_contents_qa .dlb_contents_qa-title {
      max-width: 45px !important; } }

.dlb_contents_qa .dlb_contents_qa-q {
  display: flex;
  width: 50px;
  height: 50px;
  font-size: 1.5rem;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  position: relative; }
  @media (max-width: 767.98px) {
    .dlb_contents_qa .dlb_contents_qa-q {
      width: 30px;
      height: 30px;
      font-size: 1.25rem; } }

.dlb_contents_qa .dlb_contents_qa-a {
  display: flex;
  width: 50px;
  height: 50px;
  font-size: 1.5rem;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  position: relative; }
  @media (max-width: 767.98px) {
    .dlb_contents_qa .dlb_contents_qa-a {
      width: 30px;
      height: 30px;
      font-size: 1.25rem;
      padding: 0;
      line-height: 28px; } }

.dlb_contents_boxlink-icon .dlb_contents_boxlink-item {
  background-color: #eee; }
  .dlb_contents_boxlink-icon .dlb_contents_boxlink-item .dlb_title-h3 {
    font-weight: bold; }
    @media (max-width: 991.98px) {
      .dlb_contents_boxlink-icon .dlb_contents_boxlink-item .dlb_title-h3 {
        font-weight: normal !important; } }
  .dlb_contents_boxlink-icon .dlb_contents_boxlink-item:nth-child(8n + 1) {
    background-color: #ddd; }
  .dlb_contents_boxlink-icon .dlb_contents_boxlink-item:nth-child(8n + 3) {
    background-color: #ddd; }
  .dlb_contents_boxlink-icon .dlb_contents_boxlink-item:nth-child(8n + 6) {
    background-color: #ddd; }
  .dlb_contents_boxlink-icon .dlb_contents_boxlink-item:nth-child(8n + 8) {
    background-color: #ddd; }
  @media (max-width: 767.98px) {
    .dlb_contents_boxlink-icon .dlb_contents_boxlink-item:nth-child(8n + 3) {
      background-color: #eee; }
    .dlb_contents_boxlink-icon .dlb_contents_boxlink-item:nth-child(8n + 4) {
      background-color: #ddd; }
    .dlb_contents_boxlink-icon .dlb_contents_boxlink-item:nth-child(8n + 5) {
      background-color: #ddd; }
    .dlb_contents_boxlink-icon .dlb_contents_boxlink-item:nth-child(8n + 6) {
      background-color: #eee; } }
  .dlb_contents_boxlink-icon .dlb_contents_boxlink-item img {
    transition: transform 0.3s ease; }
  .dlb_contents_boxlink-icon .dlb_contents_boxlink-item:hover img {
    transform: scale(1.08); }

.dlb_contents_boxlink-image .dlb_contents_boxlink-data {
  position: relative; }
  .dlb_contents_boxlink-image .dlb_contents_boxlink-data p:last-child {
    margin-bottom: 0; }

.dlb_contents_boxlink-image .dlb_contents_boxlink-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; }

.dlb_contents_boxlink-image .dlb_contents_boxlink-link {
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%; }

.dlb_contents_box_column-2 .dlb_contents_box-title {
  border-bottom: 1px solid #007bff;
  color: #007bff;
  text-align: center;
  padding-bottom: 16px; }
  @media (min-width: 576px) {
    .dlb_contents_box_column-2 .dlb_contents_box-title {
      border-bottom: none;
      border-right: 1px solid #007bff;
      padding-bottom: 0; } }

.dlb_contents_box_bg .dlb_contents_box_bg-item {
  background-color: #eee; }
  .dlb_contents_box_bg .dlb_contents_box_bg-item:nth-child(4n + 1) {
    background-color: #ddd; }
  .dlb_contents_box_bg .dlb_contents_box_bg-item:nth-child(4n + 4) {
    background-color: #ddd; }
  .dlb_contents_box_bg .dlb_contents_box_bg-item .dlb_contents_box_bg-badge {
    min-width: 6rem;
    max-width: 6rem;
    flex: 1 0 auto; }
    .dlb_contents_box_bg .dlb_contents_box_bg-item .dlb_contents_box_bg-badge .badge {
      margin-top: 0.25rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
  @media (max-width: 991.98px) {
    .dlb_contents_box_bg .dlb_contents_box_bg-item:nth-child(4n + 3) {
      background-color: #ddd; }
    .dlb_contents_box_bg .dlb_contents_box_bg-item:nth-child(4n + 4) {
      background-color: #eee; } }
  @media (max-width: 767.98px) {
    .dlb_contents_box_bg .dlb_contents_box_bg-item:nth-child(4n + 3) {
      background-color: #ddd; }
    .dlb_contents_box_bg .dlb_contents_box_bg-item:nth-child(4n + 4) {
      background-color: #eee; } }

.dlb_contents_book .dlb_contents-image {
  position: relative; }
  .dlb_contents_book .dlb_contents-image .new {
    position: absolute;
    border-radius: 50%;
    background-color: #ca062b;
    text-align: center;
    color: #fff;
    top: -10px;
    left: 0px;
    padding-top: 10px;
    width: 40px;
    height: 40px;
    font-size: 0.75rem; }
    @media (max-width: 767.98px) {
      .dlb_contents_book .dlb_contents-image .new {
        top: -0.25rem;
        left: 0.25rem; } }

.dlb_contents_wrap-single .dlb_contents_wrap-bg {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  height: 480px;
  margin-bottom: -10rem; }
  @media (max-width: 991.98px) {
    .dlb_contents_wrap-single .dlb_contents_wrap-bg {
      height: 316px;
      margin-bottom: -6rem; } }
  @media (max-width: 767.98px) {
    .dlb_contents_wrap-single .dlb_contents_wrap-bg {
      height: 148px;
      margin-bottom: -3rem; } }

.dlb_contents_wide-2 .dlb_contents_wide-col {
  background-position: 50% 50%; }
  .dlb_contents_wide-2 .dlb_contents_wide-col + .dlb_contents_wide-col {
    border-left: 1px solid #fff; }
    @media (max-width: 767.98px) {
      .dlb_contents_wide-2 .dlb_contents_wide-col + .dlb_contents_wide-col {
        border-left: none;
        border-top: 1px solid #fff; } }

.dlb_contents_wide-2 .dlb_contents_wide-title {
  position: relative;
  margin-bottom: 3rem; }
  .dlb_contents_wide-2 .dlb_contents_wide-title:after {
    content: '';
    position: absolute;
    bottom: -1rem;
    left: 50%;
    margin-left: -1rem;
    width: 2rem;
    height: 2px;
    background-color: #ffffff; }

.dlb_contents_card-2 .dlb_contents_card-item .shadow {
  position: relative;
  padding-bottom: 4rem; }

.dlb_contents_card-2 .dlb_contents_card-btn {
  width: 50%;
  position: absolute;
  bottom: 1.5rem;
  right: 1.5rem; }
  @media (max-width: 991.98px) {
    .dlb_contents_card-2 .dlb_contents_card-btn {
      width: auto;
      left: 1.5rem; } }

.dlb_contents_overflow {
  position: relative; }
  .dlb_contents_overflow .num {
    display: inline-block;
    padding: 8px 12px;
    margin-right: 1rem; }
  .dlb_contents_overflow .icon {
    max-width: 60px;
    margin-right: 15px; }
  .dlb_contents_overflow .dlb_contents_overflow-right {
    min-height: 360px; }
    .dlb_contents_overflow .dlb_contents_overflow-right:before {
      content: '';
      width: 60%;
      height: 100%;
      background-color: #f5f4f2;
      position: absolute;
      top: 0;
      right: 0;
      z-index: -2; }
      @media (min-width: 768px) {
        .dlb_contents_overflow .dlb_contents_overflow-right:before {
          min-height: 330px;
          top: 50% !important;
          transform: translate(0, -50%); } }
      @media (min-width: 992px) {
        .dlb_contents_overflow .dlb_contents_overflow-right:before {
          min-height: 360px; } }
      @media (max-width: 991.98px) {
        .dlb_contents_overflow .dlb_contents_overflow-right:before {
          width: 65%; } }
      @media (max-width: 767.98px) {
        .dlb_contents_overflow .dlb_contents_overflow-right:before {
          display: none; } }
    @media (max-width: 767.98px) {
      .dlb_contents_overflow .dlb_contents_overflow-right .dlb_contents_overflow-image {
        padding-left: 1rem;
        padding-right: 1rem; } }
    .dlb_contents_overflow .dlb_contents_overflow-right .dlb_contents_overflow-data {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem; }
      @media (max-width: 767.98px) {
        .dlb_contents_overflow .dlb_contents_overflow-right .dlb_contents_overflow-data {
          margin-top: -3rem;
          padding-top: 4rem;
          padding-bottom: 1.5rem;
          background-color: #f5f4f2;
          z-index: -1; } }
  .dlb_contents_overflow .dlb_contents_overflow-left {
    min-height: 360px; }
    .dlb_contents_overflow .dlb_contents_overflow-left:before {
      content: '';
      width: 60%;
      height: 100%;
      background-color: #f5f4f2;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -2; }
      @media (min-width: 768px) {
        .dlb_contents_overflow .dlb_contents_overflow-left:before {
          min-height: 330px;
          top: 50% !important;
          transform: translate(0, -50%); } }
      @media (min-width: 992px) {
        .dlb_contents_overflow .dlb_contents_overflow-left:before {
          min-height: 360px; } }
      @media (max-width: 991.98px) {
        .dlb_contents_overflow .dlb_contents_overflow-left:before {
          width: 65%; } }
      @media (max-width: 767.98px) {
        .dlb_contents_overflow .dlb_contents_overflow-left:before {
          display: none; } }
    @media (max-width: 767.98px) {
      .dlb_contents_overflow .dlb_contents_overflow-left .dlb_contents_overflow-image {
        padding-left: 1rem;
        padding-right: 1rem; } }
    .dlb_contents_overflow .dlb_contents_overflow-left .dlb_contents_overflow-data {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem; }
      @media (max-width: 767.98px) {
        .dlb_contents_overflow .dlb_contents_overflow-left .dlb_contents_overflow-data {
          margin-top: -3rem;
          padding-top: 4rem;
          padding-bottom: 1.5rem;
          background-color: #f5f4f2;
          z-index: -1; } }

.dlb_indent-link {
  padding-left: 1rem;
  text-indent: -0.8rem; }

.dlb_slide-2 .carousel-control-prev a {
  display: flex;
  width: 100%;
  height: 100%;
  text-align: center;
  align-items: center; }

.dlb_slide-2 .carousel-control-prev .carousel-control-prev-icon {
  flex: 1 0 auto;
  background-size: contain; }

.dlb_slide-2 .carousel-control-next a {
  display: flex;
  width: 100%;
  height: 100%;
  text-align: center;
  align-items: center; }

.dlb_slide-2 .carousel-control-next .carousel-control-next-icon {
  flex: 1 0 auto;
  background-size: contain; }

.dlb_slide-2 .dlb_carousel-thumb {
  margin: 0 -5px; }
  .dlb_slide-2 .dlb_carousel-thumb .col {
    padding: 0 5px; }

.dlb_slide-3 .container {
  position: relative; }

.dlb_slide-3 .dlb_slide-prev {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%; }
  .dlb_slide-3 .dlb_slide-prev::before {
    content: '';
    position: relative;
    top: 50%;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 0.5rem;
    border-top: 4px solid #000000;
    border-right: 4px solid #000000;
    transform: rotate(225deg);
    display: block;
    margin-top: -8px; }

.dlb_slide-3 .dlb_slide-next {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%; }
  .dlb_slide-3 .dlb_slide-next::before {
    content: '';
    position: relative;
    top: 50%;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 0.5rem;
    border-top: 4px solid #000000;
    border-right: 4px solid #000000;
    transform: rotate(45deg);
    display: block;
    margin-top: -8px; }

.dlb_slide-4 .carousel-control-prev a {
  display: flex;
  width: 100%;
  height: 100%;
  text-align: center;
  align-items: center; }

.dlb_slide-4 .carousel-control-prev .carousel-control-prev-icon {
  flex: 1 0 auto;
  background-size: contain; }

.dlb_slide-4 .carousel-control-next a {
  display: flex;
  width: 100%;
  height: 100%;
  text-align: center;
  align-items: center; }

.dlb_slide-4 .carousel-control-next .carousel-control-next-icon {
  flex: 1 0 auto;
  background-size: contain; }

.dlb_slide-4 .dlb_slide-data .dlb_slide-item {
  line-height: 1.5; }

.dlb_slide-4 .dlb_slide_list {
  padding: 0 15px; }
  .dlb_slide-4 .dlb_slide_list .row {
    margin: 0 -5px; }
    .dlb_slide-4 .dlb_slide_list .row > * {
      padding: 0 5px; }

.dlb_slide-5 .container {
  position: relative; }

.dlb_slide-5 .dlb_slide-prev {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%; }
  .dlb_slide-5 .dlb_slide-prev::before {
    content: '';
    position: relative;
    top: 50%;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 0.5rem;
    border-top: 4px solid #000000;
    border-right: 4px solid #000000;
    transform: rotate(225deg);
    display: block;
    margin-top: -10px; }

.dlb_slide-5 .dlb_slide-next {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%; }
  .dlb_slide-5 .dlb_slide-next::before {
    content: '';
    position: relative;
    top: 50%;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 0.5rem;
    border-top: 4px solid #000000;
    border-right: 4px solid #000000;
    transform: rotate(45deg);
    display: block;
    margin-top: -10px; }

.dlb_flow-1 .dlb_flow-item {
  position: relative; }
  .dlb_flow-1 .dlb_flow-item + .dlb_flow-item::before {
    content: '';
    position: absolute;
    top: 47%;
    left: -10px;
    display: block;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 25px 0 25px 20px;
    border-color: transparent transparent transparent #ffc732; }
    @media (max-width: 991.98px) {
      .dlb_flow-1 .dlb_flow-item + .dlb_flow-item::before {
        top: -25px;
        left: 49%;
        transform: rotate(90deg); } }

.dlb_flow-1 .dlb_flow-title {
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap; }

.dlb_flow-1 .dlb_flow-inner {
  display: block;
  flex: 1 0 auto;
  max-width: 100%; }

.dlb_flow-2 .dlb_flow-item {
  position: relative; }
  .dlb_flow-2 .dlb_flow-item + .dlb_flow-item {
    margin-top: 48px; }
    .dlb_flow-2 .dlb_flow-item + .dlb_flow-item .dlb_flow-title::before {
      content: '';
      position: absolute;
      top: -35px;
      left: 50%;
      display: block;
      width: 0px;
      height: 0px;
      margin-left: -25px;
      border-style: solid;
      border-width: 20px 25px 0 25px;
      border-color: #ffc732 transparent transparent transparent; }
    @media (max-width: 767.98px) {
      .dlb_flow-2 .dlb_flow-item + .dlb_flow-item {
        position: static; } }
  @media (max-width: 767.98px) {
    .dlb_flow-2 .dlb_flow-item .dlb_flow-title > .row {
      padding: 16px; } }
  @media (min-width: 576px) {
    .dlb_flow-2 .dlb_flow-item .dlb_flow-data {
      border: none !important; } }

.dlb_flow-3 .dlb_flow-item {
  position: relative;
  margin-bottom: 48px; }
  .dlb_flow-3 .dlb_flow-item .dlb_flow-title::before {
    content: '';
    position: absolute;
    bottom: -35px;
    left: 0;
    display: block;
    width: 100%;
    height: 35px;
    background: linear-gradient(to bottom left, #007bff 49%, transparent 50%) top left/50% 100% no-repeat, linear-gradient(to bottom right, #007bff 49%, transparent 50%) top right/50% 100% no-repeat; }
  @media (max-width: 767.98px) {
    .dlb_flow-3 .dlb_flow-item .dlb_flow-title > .row {
      padding: 16px; } }
  @media (min-width: 576px) {
    .dlb_flow-3 .dlb_flow-item .dlb_flow-data {
      border: none !important; } }

.dlb_flow-4 .dlb_flow-item {
  position: relative; }
  .dlb_flow-4 .dlb_flow-item + .dlb_flow-item {
    margin-top: 48px; }
    .dlb_flow-4 .dlb_flow-item + .dlb_flow-item .dlb_flow-title::before {
      content: '';
      position: absolute;
      top: -35px;
      left: 50%;
      display: block;
      width: 0px;
      height: 0px;
      margin-left: -25px;
      border-style: solid;
      border-width: 20px 25px 0 25px;
      border-color: #ffc732 transparent transparent transparent; }
    @media (max-width: 767.98px) {
      .dlb_flow-4 .dlb_flow-item + .dlb_flow-item {
        position: static; } }
  @media (max-width: 767.98px) {
    .dlb_flow-4 .dlb_flow-item .dlb_flow-title > .row {
      padding: 16px; } }
  @media (min-width: 576px) {
    .dlb_flow-4 .dlb_flow-item .dlb_flow-data {
      border-color: #dee2e6 !important; } }

.dlb_flow-5 .dlb_flow-item {
  position: relative;
  border-width: 4px !important;
  border-radius: 10px; }
  .dlb_flow-5 .dlb_flow-item + .dlb_flow-item {
    margin-top: 48px; }
    .dlb_flow-5 .dlb_flow-item + .dlb_flow-item::before {
      content: '';
      position: absolute;
      top: -35px;
      left: 50%;
      display: block;
      width: 0px;
      height: 0px;
      margin-left: -25px;
      border-style: solid;
      border-width: 20px 25px 0 25px;
      border-color: #777777 transparent transparent transparent; }

.dlb_flow-5 .dlb_flow-title {
  position: relative;
  padding-left: 90px;
  margin-bottom: 1rem; }
  @media (max-width: 767.98px) {
    .dlb_flow-5 .dlb_flow-title {
      padding-left: 0; } }

.dlb_flow-5 .dlb_flow-label {
  display: inline-block;
  position: absolute;
  top: 0px;
  left: -34px;
  padding: 0.5rem 1.5rem;
  margin-right: -24px;
  background-color: #777777;
  color: #fff; }
  .dlb_flow-5 .dlb_flow-label:before {
    position: absolute;
    content: '';
    left: 0;
    bottom: -8px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6px 8px 0;
    border-color: transparent #555555 transparent transparent; }

.dlb_list_accordion .btn {
  position: relative;
  color: inherit !important;
  padding-right: 40px !important; }
  .dlb_list_accordion .btn::after {
    content: '';
    position: relative;
    top: 50%;
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 0.5rem;
    border-top: 2px solid #000000;
    border-right: 2px solid #000000;
    transform: rotate(315deg);
    position: absolute;
    right: 16px;
    margin-top: -6px;
    transition: transform 0.3s ease;
    transform-origin: 50% 50% 0; }
  .dlb_list_accordion .btn.collapsed::after {
    transform: rotate(135deg); }

.dlb_table_row .table th {
  background-image: none; }

.dlb_table_row .dlb_table_row-title {
  width: 100%; }
  @media (min-width: 768px) {
    .dlb_table_row .dlb_table_row-title {
      width: 25%; } }

.dlb_table_row-striped .table th {
  background-image: none; }

.dlb_table_row-striped .dlb_table_row-title {
  width: 100%; }
  @media (min-width: 768px) {
    .dlb_table_row-striped .dlb_table_row-title {
      width: 25%; } }

.dlb_table_column .table th {
  background-image: none; }

.dlb_table_column-striped .table th {
  background-image: none; }

.dlb_table_stack .dlb_table_stack-data {
  border: 1px solid #dee2e6; }
  @media (min-width: 768px) {
    .dlb_table_stack .dlb_table_stack-data {
      border: none; } }

.dlb_btn_column-3 .container {
  padding-right: 16px;
  padding-left: 16px; }

.dlb_btn_column-3 .row {
  margin-right: -8px;
  margin-left: -8px; }
  .dlb_btn_column-3 .row .col {
    padding-right: 8px;
    padding-left: 8px; }

.dlb_btn_column-3 .d-table a {
  display: table-cell; }

.dlb_btn_column-4 .container {
  padding-right: 16px;
  padding-left: 16px; }

.dlb_btn_column-4 .row {
  margin-right: -8px;
  margin-left: -8px; }
  .dlb_btn_column-4 .row .col {
    padding-right: 8px;
    padding-left: 8px; }

.dlb_btn_column-4 .d-table a {
  display: table-cell; }

.dlb_btn_column-5 .container {
  padding-right: 16px;
  padding-left: 16px; }

.dlb_btn_column-5 .row {
  margin-right: -8px;
  margin-left: -8px; }
  .dlb_btn_column-5 .row .col {
    padding-right: 8px;
    padding-left: 8px; }

.dlb_btn_column-5 .d-table a {
  display: table-cell; }

.dlb_btn_link_column-4 .dlb_btn-item {
  transition: box-shadow 0.2s ease; }
  .dlb_btn_link_column-4 .dlb_btn-item:hover {
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1); }

.dlb_btn_link_column-3 .dlb_btn-item {
  position: relative;
  transition: box-shadow 0.2s ease; }
  .dlb_btn_link_column-3 .dlb_btn-item .row > [class*='col-'] {
    position: static; }
  .dlb_btn_link_column-3 .dlb_btn-item:hover {
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1); }

.dlb_btn_card_column-3 .shadow {
  transition: box-shadow 0.3s ease; }
  .dlb_btn_card_column-3 .shadow:hover {
    box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.3) !important; }
  .dlb_btn_card_column-3 .shadow a {
    text-decoration: none; }

.dlb_btn_card_column-4 {
  overflow: unset; }
  @media (min-width: 768px) {
    .dlb_btn_card_column-4 .row {
      margin-right: -8px;
      margin-left: -8px; }
    .dlb_btn_card_column-4 .col {
      padding-right: 8px;
      padding-left: 8px; } }
  @media (min-width: 992px) {
    .dlb_btn_card_column-4 .row {
      margin-right: -15px;
      margin-left: -15px; }
    .dlb_btn_card_column-4 .col {
      padding-right: 15px;
      padding-left: 15px; } }

.dlb_btn_link-text {
  overflow: unset; }
  .dlb_btn_link-text .stretched-link {
    height: 100%; }
    .dlb_btn_link-text .stretched-link a {
      height: 100%;
      display: flex;
      align-items: center; }
    .dlb_btn_link-text .stretched-link img {
      margin-right: 0.5rem; }

.dlb_blog_column-4 .container {
  position: relative; }

.dlb_blog_column-4 .dlb_blog-item {
  padding: 1rem; }
  .dlb_blog_column-4 .dlb_blog-item a {
    color: inherit; }
  .dlb_blog_column-4 .dlb_blog-item.stretched-link a {
    position: relative;
    display: block; }
    .dlb_blog_column-4 .dlb_blog-item.stretched-link a::after {
      right: 0;
      left: 0; }

.dlb_blog_column-4 .dlb_blog-data {
  position: relative; }
  .dlb_blog_column-4 .dlb_blog-data .dlb_title-h3 {
    font-size: 1.25rem;
    max-height: 3.7rem;
    overflow: hidden; }
  .dlb_blog_column-4 .dlb_blog-data p {
    margin-bottom: 0;
    max-height: 6rem;
    overflow: hidden; }

.dlb_blog_column-4 .dlb_blog-badge {
  position: absolute;
  top: -32px;
  right: 0;
  text-align: right; }

.dlb_blog_column-4 .dlb_slide-prev {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%; }
  .dlb_blog_column-4 .dlb_slide-prev::before {
    content: '';
    position: relative;
    top: 50%;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 0.5rem;
    border-top: 4px solid #000000;
    border-right: 4px solid #000000;
    transform: rotate(225deg);
    display: block;
    margin-top: -10px;
    left: 10px; }

.dlb_blog_column-4 .dlb_slide-next {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%; }
  .dlb_blog_column-4 .dlb_slide-next::before {
    content: '';
    position: relative;
    top: 50%;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 0.5rem;
    border-top: 4px solid #000000;
    border-right: 4px solid #000000;
    transform: rotate(45deg);
    display: block;
    margin-top: -10px;
    left: -3px; }

.dlb_blog_column_image-left .dlb_blog-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 1.5rem;
  color: inherit; }
  .dlb_blog_column_image-left .dlb_blog-item .dlb_title-h3 {
    font-size: 1.25rem;
    max-height: 3.7rem;
    overflow: hidden; }
  .dlb_blog_column_image-left .dlb_blog-item:first-child {
    margin-top: 0; }
  @media (min-width: 768px) {
    .dlb_blog_column_image-left .dlb_blog-item:nth-child(2) {
      margin-top: 0; } }

.dlb_blog_column_image-left .dlb_blog-image {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 50%;
  max-width: 50%; }
  @media (max-width: 767.98px) {
    .dlb_blog_column_image-left .dlb_blog-image {
      flex: 0 0 100%;
      max-width: 100%; } }

.dlb_blog_column_image-left .dlb_blog-data {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 50%;
  max-width: 50%; }
  @media (max-width: 991.98px) {
    .dlb_blog_column_image-left .dlb_blog-data {
      padding-left: 0; } }
  @media (max-width: 767.98px) {
    .dlb_blog_column_image-left .dlb_blog-data {
      flex: 0 0 100%;
      max-width: 100%;
      padding-left: 15px; } }

.rss_new,
.rss_news {
  font-size: 90%;
  padding: 0.25rem 0.5rem;
  text-align: center; }

.dlb_news .rss_new,
.dlb_news .rss_news,
.dlb_news-body .rss_new,
.dlb_news-body .rss_news,
.dlb_news_pickup .rss_new,
.dlb_news_pickup .rss_news,
.dlb_news_tab .rss_new,
.dlb_news_tab .rss_news,
.dlb_news-list .rss_new,
.dlb_news-list .rss_news,
.dlb_news_column .rss_new,
.dlb_news_column .rss_news,
.dlb_news_blog .rss_new,
.dlb_news_blog .rss_news {
  margin-right: 0.5rem;
  max-width: 20%;
  min-width: 6rem; }
  @media (max-width: 767.98px) {
    .dlb_news .rss_new,
    .dlb_news .rss_news,
    .dlb_news-body .rss_new,
    .dlb_news-body .rss_news,
    .dlb_news_pickup .rss_new,
    .dlb_news_pickup .rss_news,
    .dlb_news_tab .rss_new,
    .dlb_news_tab .rss_news,
    .dlb_news-list .rss_new,
    .dlb_news-list .rss_news,
    .dlb_news_column .rss_new,
    .dlb_news_column .rss_news,
    .dlb_news_blog .rss_new,
    .dlb_news_blog .rss_news {
      position: absolute;
      top: -32px;
      left: 100px;
      max-width: 60%;
      max-width: calc(100% - 100px);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; } }

.dlb_news_boxlink .rss_new,
.dlb_news_boxlink .rss_news {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 0; }

.list-group-item {
  overflow: auto; }

.dlb_news dl,
.dlb_news dd {
  margin-bottom: 0; }

.dlb_news dt {
  font-weight: normal; }

.dlb_news .rss_date {
  flex: 0 0 8rem;
  text-align: left;
  padding: 0 15px; }
  @media (min-width: 576px) {
    .dlb_news .rss_date {
      text-align: center; } }

.dlb_news .rss_title a {
  display: flex;
  align-items: baseline; }

.dlb_news .rss_contents {
  display: none;
  margin-top: 0.5rem;
  padding-top: 0;
  padding-left: 8rem !important;
  box-sizing: border-box; }
  .dlb_news .rss_contents:empty {
    margin-top: 0; }
  @media (max-width: 767.98px) {
    .dlb_news .rss_contents {
      padding-left: 0 !important; } }

.dlb_news .dlb_title-h3 {
  position: relative; }
  .dlb_news .dlb_title-h3 .btn {
    position: absolute;
    right: 0;
    top: 0; }

.dlb_news-body .rss_contents {
  display: block !important; }

.dlb_news_pickup dl,
.dlb_news_pickup dd {
  margin-bottom: 0; }

.dlb_news_pickup dt {
  font-weight: normal; }

.dlb_news_pickup .rss_date {
  flex: 0 0 8rem;
  text-align: left;
  padding: 0 15px; }
  @media (min-width: 576px) {
    .dlb_news_pickup .rss_date {
      text-align: center; } }

.dlb_news_pickup .rss_title a {
  display: flex;
  align-items: center; }

.dlb_news_pickup .rss_contents {
  display: none;
  margin-top: 0.5rem;
  padding-top: 0;
  padding-left: 8rem !important;
  box-sizing: border-box; }
  @media (max-width: 767.98px) {
    .dlb_news_pickup .rss_contents {
      padding-left: 0 !important; } }

.dlb_news_pickup .dlb_title-h3 {
  position: relative; }
  .dlb_news_pickup .dlb_title-h3 .btn {
    position: absolute;
    right: 0;
    top: 0; }

.dlb_news_pickup .dlb_news_pickup-figure {
  position: relative; }
  .dlb_news_pickup .dlb_news_pickup-figure figcaption {
    width: 100%;
    padding: 8px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff; }

.dlb_news_pickup .rss_contents {
  display: none;
  margin-top: 0.5rem;
  padding-top: 0;
  padding-left: 8rem !important;
  box-sizing: border-box; }
  @media (max-width: 767.98px) {
    .dlb_news_pickup .rss_contents {
      padding-left: 0 !important; } }

.dlb_news_tab .rss_date {
  flex: 0 0 8rem;
  text-align: left;
  padding: 0 15px; }
  @media (min-width: 576px) {
    .dlb_news_tab .rss_date {
      text-align: center; } }

.dlb_news_tab .rss_title a {
  display: flex;
  align-items: center; }

.dlb_news_tab .rss_contents {
  display: none;
  margin-top: 0.5rem;
  padding-top: 0;
  padding-left: 8rem !important;
  box-sizing: border-box; }
  @media (max-width: 767.98px) {
    .dlb_news_tab .rss_contents {
      padding-left: 0 !important; } }

.dlb_news_tab .dlb_title-h3 {
  position: relative; }
  .dlb_news_tab .dlb_title-h3 .btn {
    position: absolute;
    right: 0;
    top: 0; }

.dlb_news_boxlink dl,
.dlb_news_boxlink dd {
  margin-bottom: 0; }

.dlb_news_boxlink dt {
  font-weight: normal; }

.dlb_news_boxlink .dlb_news_boxlink-item {
  position: relative;
  padding: 32px 24px;
  border: 0.5rem solid #fff !important; }
  .dlb_news_boxlink .dlb_news_boxlink-item .badge {
    position: absolute;
    top: 0;
    left: 0;
    padding: 8px; }

.dlb_news_boxlink .dlb_news-list {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  margin-top: 0;
  margin-bottom: 0;
  align-items: stretch !important;
  padding: 15px !important; }
  .dlb_news_boxlink .dlb_news-list > * {
    flex: 0 0 100% !important;
    max-width: 100% !important; }
    @media (min-width: 768px) {
      .dlb_news_boxlink .dlb_news-list > * {
        flex: 0 0 50% !important;
        max-width: 50% !important; } }
    @media (min-width: 992px) {
      .dlb_news_boxlink .dlb_news-list > * {
        flex: 0 0 25% !important;
        max-width: 25% !important; } }

.dlb_news_boxlink .list-group-item {
  display: block !important;
  background-color: #f8f9fa !important;
  position: relative;
  padding: 32px 24px !important;
  border: 0.5rem solid #fff !important; }
  .dlb_news_boxlink .list-group-item .badge {
    position: absolute;
    top: 0;
    left: 0;
    padding: 8px;
    border-radius: 0; }

.dlb_news_boxlink .rss_date {
  position: static; }

.dlb_news_boxlink .rss_title {
  position: static;
  display: block;
  margin-bottom: 0; }

.dlb_news_boxlink .rss_contents {
  display: none; }

.dlb_news-list dl,
.dlb_news-list dd {
  margin-bottom: 0; }

.dlb_news-list dt {
  font-weight: normal; }

.dlb_news-list .rss_title a {
  vertical-align: middle; }

.dlb_news-list .rss_title span {
  vertical-align: baseline; }

.dlb_news-list .list-group-item {
  border-right: none;
  border-left: none; }

.dlb_news_column .rss_title a {
  display: flex;
  align-items: flex-start; }
  .dlb_news_column .rss_title a span {
    flex: 0 0 auto;
    max-width: 7rem;
    margin-right: 1rem; }

.dlb_news_column .rss_date {
  flex: 0 0 100%; }

.dlb_news_column .rss_title {
  flex: 0 0 100%; }

.dlb_news_column .rss_contents {
  display: none; }

.dlb_news_blog dl,
.dlb_news_blog dd {
  margin-bottom: 0; }

.dlb_news_blog dt {
  font-weight: normal; }

.dlb_news_blog .dlb_title-h3 {
  position: relative; }
  .dlb_news_blog .dlb_title-h3 .btn {
    position: absolute;
    right: 0;
    top: 0; }
    @media (max-width: 767.98px) {
      .dlb_news_blog .dlb_title-h3 .btn {
        position: static;
        display: block;
        margin: 1rem auto 0;
        max-width: 80%; } }

.dlb_news_blog .dlb_news_pickup-figure {
  position: relative; }
  .dlb_news_blog .dlb_news_pickup-figure figcaption {
    width: 100%;
    padding: 8px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff; }

.dlb_news_blog .dlb_news-list .list-group-item:first-child {
  border-top: none; }

.dlb_news_blog .dlb_news-list .rss_title a {
  display: flex;
  align-items: flex-start; }
  .dlb_news_blog .dlb_news-list .rss_title a span {
    flex: 1 0 1rem;
    max-width: 7rem;
    margin-right: 1rem; }

.dlb_news_blog .dlb_news-list .rss_date {
  flex: 0 0 7rem;
  margin-bottom: 0.5rem; }

.dlb_news_blog .dlb_news-list .rss_contents {
  display: none; }

.dlb_news_blog .dlb_news-list .dlb_blog-item {
  padding: 1rem;
  position: relative; }
  .dlb_news_blog .dlb_news-list .dlb_blog-item + .dlb_blog-item {
    border-top: 1px solid #ccc; }

.dlb_news_blog .dlb_news-list .dlb_blog-image {
  display: none; }

.dlb_news_blog .dlb_news-list .dlb_blog-data span {
  padding-right: 7rem;
  margin-bottom: 0.75rem; }

.dlb_news_blog .dlb_news-list .dlb_blog-data h3 {
  font-size: 1.25rem;
  margin-bottom: 0.5rem; }

.dlb_news_blog .dlb_news-list .dlb_blog-data p {
  margin-bottom: 0; }

.dlb_news_blog .dlb_news-list .dlb_blog-data div {
  position: absolute;
  top: 0.9rem;
  right: 1rem; }

.dlb_news_blog .dlb_news-blog {
  padding-left: 1.5rem; }
  .dlb_news_blog .dlb_news-blog p .dlb_title-h3 {
    position: relative;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 2px solid #ccc; }
    .dlb_news_blog .dlb_news-blog p .dlb_title-h3 .btn {
      position: absolute;
      right: 0;
      top: 0; }
  .dlb_news_blog .dlb_news-blog .list-group-item {
    position: relative;
    border: none; }
    .dlb_news_blog .dlb_news-blog .list-group-item + .list-group-item {
      border-top: 1px solid #ccc; }
  .dlb_news_blog .dlb_news-blog .rss_date {
    padding-right: 7rem;
    margin-bottom: 0.5rem; }
  .dlb_news_blog .dlb_news-blog .rss_title a {
    display: flex; }
  .dlb_news_blog .dlb_news-blog .rss_title span {
    position: absolute;
    top: 0.9rem;
    right: 1rem; }

.dlb_contact_column {
  background-position: 50% 50%;
  background-size: cover;
  margin-top: 48px;
  margin-bottom: 48px; }
  .dlb_contact_column .dlb_contact-tel {
    font-size: 3.5rem;
    font-weight: bold; }
    @media (max-width: 991.98px) {
      .dlb_contact_column .dlb_contact-tel {
        font-size: 2rem; } }
    @media (max-width: 767.98px) {
      .dlb_contact_column .dlb_contact-tel {
        font-size: 2rem; } }

.dlb_contact_rows {
  margin-top: 48px;
  margin-bottom: 48px; }
  .dlb_contact_rows .dlb_contact-tel {
    font-size: 3.5rem;
    font-weight: bold; }
    @media (max-width: 991.98px) {
      .dlb_contact_rows .dlb_contact-tel {
        font-size: 3rem; } }
    @media (max-width: 767.98px) {
      .dlb_contact_rows .dlb_contact-tel {
        font-size: 2rem; } }

.dlb_text_point .container {
  overflow: visible;
  position: relative; }
  .dlb_text_point .container:before {
    content: '';
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    background-color: #ccc; }

.dlb_text_point .dlb_text_point-title {
  position: relative; }

.dlb_contact-area .col {
  border: 1px solid #555555; }
  .dlb_contact-area .col + .col {
    border-left: none; }
    @media (max-width: 991.98px) {
      .dlb_contact-area .col + .col {
        border-left: 1px solid #555555; } }

.dlb_contact-area .dlb_contact-title {
  position: relative;
  margin-bottom: 0;
  padding: 10px 10px 10px 80px; }
  @media (max-width: 991.98px) {
    .dlb_contact-area .dlb_contact-title {
      padding: 6px 6px 6px 60px; } }

.dlb_contact-area .dlb_contact-icon {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 60px; }
  @media (max-width: 991.98px) {
    .dlb_contact-area .dlb_contact-icon {
      width: 40px; } }

.dlb_contact-area .dlb_contact-list {
  margin-bottom: 1.5rem;
  padding: 0px 0px 0px 80px; }
  @media (max-width: 991.98px) {
    .dlb_contact-area .dlb_contact-list {
      padding-left: 60px; } }
  .dlb_contact-area .dlb_contact-list li {
    border-bottom: 1px solid #ccc; }
    .dlb_contact-area .dlb_contact-list li a {
      display: block;
      padding: 0.5rem; }

.dlb_contact-area .dlb_contact-tel {
  margin-bottom: 1.5rem;
  padding-right: 1rem; }
  .dlb_contact-area .dlb_contact-tel .dlb_contact-icon {
    position: static;
    padding-right: 15px;
    width: inherit;
    text-align: right; }
    .dlb_contact-area .dlb_contact-tel .dlb_contact-icon img {
      width: 50px; }
      @media (max-width: 991.98px) {
        .dlb_contact-area .dlb_contact-tel .dlb_contact-icon img {
          width: 30px; } }
  .dlb_contact-area .dlb_contact-tel .dlb_contact-number {
    font-size: 2.5rem; }
    @media (max-width: 991.98px) {
      .dlb_contact-area .dlb_contact-tel .dlb_contact-number {
        font-size: 2rem; } }

.dlb_contact-area .dlb_contact-box {
  background-position: right bottom;
  background-repeat: no-repeat; }

.dlb_contact-area .dlb_contact-text {
  position: relative;
  background-color: #ebf4f9;
  width: 50%;
  padding: 1.5rem;
  top: 10px;
  left: 1rem; }
  @media (max-width: 991.98px) {
    .dlb_contact-area .dlb_contact-text {
      width: 35%;
      padding: 1rem;
      font-size: 12px;
      top: 0; } }
  @media (max-width: 767.98px) {
    .dlb_contact-area .dlb_contact-text {
      width: 40%;
      padding: 1rem;
      font-size: 12px;
      top: 1rem; } }
  .dlb_contact-area .dlb_contact-text:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: -18px;
    margin-top: -1rem;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 18px 0 18px 18px;
    border-color: transparent transparent transparent #ebf4f9; }

.dlb_title-sub-01 {
  position: relative;
  border-left: 4px solid #ccc;
  padding-left: 20px;
  padding-bottom: 4px;
  margin-bottom: 2.5rem;
  font-weight: bold;
  padding-top: 2px; }
  @media (max-width: 767.98px) {
    .dlb_title-sub-01 {
      margin-bottom: 2rem; } }
  .dlb_title-sub-01::after {
    content: '';
    position: absolute;
    bottom: -16px;
    left: 0;
    right: 0;
    display: block;
    height: 1px;
    background-color: #ddd; }

.dlb_title-sub-02 {
  border-bottom: 1px solid #ccc;
  border-left: 8px solid #ccc;
  padding-left: 16px;
  padding-bottom: 0.25em;
  margin-bottom: 2rem;
  font-weight: bold; }

.dlb_title-sub-03 {
  border-left: 4px solid #ccc;
  padding-left: 16px;
  font-weight: bold; }

.dlb_title-sub-04 {
  position: relative;
  font-weight: bold;
  padding-left: 1em;
  text-indent: -1em; }
  .dlb_title-sub-04::before {
    content: "";
    display: inline-block;
    width: 0.5em;
    height: 0.75em;
    margin-right: 0.5em;
    background-color: #ddd; }

.dlb_title-sub-05 {
  position: relative;
  border-bottom: 1px solid #ccc;
  padding-bottom: 0.25em;
  font-weight: bold; }

.dlb_title-sub-06 {
  position: relative;
  font-weight: bold;
  padding-bottom: 0.5em;
  text-align: center; }
  .dlb_title-sub-06::after {
    content: "";
    position: absolute;
    display: block;
    bottom: 0;
    left: 50%;
    width: 1em;
    height: 0.2em;
    min-height: 1px;
    margin-left: -0.5em;
    background-color: #ccc; }

.dlb_title-sub-07 {
  position: relative;
  padding: 0.125em 0.25em;
  background-color: #ccc;
  font-weight: bold; }

.dlb_parallax-bg {
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 300px;
  margin-top: 3rem;
  margin-bottom: 3rem; }
  @media (max-width: 991.98px) {
    .dlb_parallax-bg {
      background-attachment: unset; } }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    .dlb_parallax-bg {
      background-attachment: unset; } }

.dlb_footer-fixed {
  position: fixed;
  bottom: -92px;
  left: 0;
  z-index: 1000;
  transition: 0.3s ease;
  background-color: #fff;
  opacity: 0; }
  .dlb_footer-fixed.scroll-in {
    bottom: 0;
    opacity: 1; }
  .dlb_footer-fixed.scroll-stop {
    position: fixed;
    opacity: 0;
    bottom: -92px; }

.share_top-link {
  position: fixed;
  bottom: 0px;
  right: 0;
  left: 0;
  z-index: 1000; }
  .share_top-link .container {
    position: relative;
    margin-top: 0;
    margin-bottom: 0; }
  .share_top-link .top-link {
    position: absolute;
    top: -135px;
    right: 0; }
    @media (max-width: 991.98px) {
      .share_top-link .top-link {
        top: -172px;
        right: 10px; } }
    @media (max-width: 767.98px) {
      .share_top-link .top-link {
        top: -60px;
        right: 10px; } }
    .share_top-link .top-link .btn {
      width: 50px;
      height: 50px;
      border-radius: 0.25rem !important; }
      .share_top-link .top-link .btn a {
        display: block;
        width: 100%;
        height: 100%;
        padding: 8px 16px;
        overflow: hidden; }
        .share_top-link .top-link .btn a::before {
          content: '';
          position: relative;
          top: 12px;
          left: -3px;
          display: inline-block;
          width: 24px;
          height: 24px;
          border-top: 2px solid #fff;
          border-right: 2px solid #fff;
          transform: rotate(-45deg); }

#dcms_inner_body + #dcms_layoutPageBlockPasteArea .bm_slider-item,
.editor_message + #dcms_layoutPageBlockPasteArea .bm_slider-item {
  max-width: 25%;
  float: left; }

#tinymce .bm_slider-item {
  max-width: 25%;
  float: left; }

#tinymce .js_open,
#tinymce .js_morelink {
  display: block; }

#tinymce .collapse:not(.show) {
  display: block; }

#tinymce .modal {
  position: static;
  display: block;
  width: auto;
  height: auto;
  overflow: unset; }

#tinymce .fade:not(.show) {
  opacity: 1; }

#tinymce .modal-dialog {
  pointer-events: painted; }

#tinymce .tab-pane {
  display: block; }

#tinymce .dlb_slide-1 img {
  max-width: 25%; }

#tinymce .dlb_slide-1 .dlb_slide_1-siema > div,
#tinymce .dlb_slide-1 .dlb_slide_1-siema .img-fluid {
  display: inline; }

#tinymce .swiper-container {
  overflow: unset; }

#tinymce .swiper-wrapper {
  display: block; }

#tinymce .swiper-slide {
  display: inline; }
  #tinymce .swiper-slide img {
    max-height: 200px;
    width: auto; }

.dcms_editor .modal {
  height: auto; }

.dcms_editor .dlb_slide-1 img {
  max-width: 24.8%;
  margin-bottom: 2px; }

.dcms_editor .dlb_slide-1 .dlb_slide_1-siema > div,
.dcms_editor .dlb_slide-1 .dlb_slide_1-siema .img-fluid {
  display: inline; }

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: -10px; }

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  right: -10px; }

:root {
  --swiper-navigation-size: 24px;
  --swiper-navigation-color: #000;
  --swiper-theme-color: #000; }
