// --------------------------------------------------
// メディアクエリ用
// --------------------------------------------------
$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 576px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 992px,
  // Extra large screen / wide desktop
  xl: 1200px
);
@import 'mixins/breakpoints';

// btn arrow mixin
// $size = width & height, $top = top, $border-width = border-width, $border-color = border-color, $rotate = XXdeg
@mixin btn-arrow($size: 8px, $top: -2px, $border-width: 2px, $border-color: #000000, $rotate: 45deg) {
  content: '';
  position: relative;
  top: $top;
  display: inline-block;
  width: $size;
  height: $size;
  margin-right: 0.5rem;
  border-top: $border-width solid $border-color;
  border-right: $border-width solid $border-color;
  transform: rotate($rotate);
}

//
* {
  line-break: strict;
}

// メディアクエリ用 使用例
// .XXX {
//   @include media-breakpoint-up(sm) {
//     background-color: #eeee33;
//   }
// }

// --------------------------------------------------
// BM ダウンロードブロック用記述 LP
// --------------------------------------------------
// キャンペーン情報 01
.bm_campaign-01 {
}
// キャンペーン情報 02
.bm_campaign-02 {
}
// キャンペーン情報 03
.bm_campaign-03 {
}
// プレゼント内容
.bm_present-01 {
}
// SNS画像 01
.bm_sns-01 {
}
// SNS画像 02
.bm_sns-02 {
}
// 応募方法 01
.bm_entry-01 {
  .bm_entry-title {
    background-color: #e9ecef;
    padding: 1rem;
    margin-bottom: 0;
    text-align: center;
  }
  .bm_entry-body {
    background-color: #f8f9fa;
    margin-bottom: 1rem;
    padding: 1rem;
  }
}
// 応募方法 02
.bm_entry-02 {
  .bm_entry-title {
    background-color: #e9ecef;
    padding: 1rem;
    margin-bottom: 0;
    text-align: center;
  }
  .bm_entry-body {
    background-color: #f8f9fa;
    margin-bottom: 1rem;
    padding: 1rem;
  }
}
// 応募方法 03
.bm_entry-03 {
  .bm_entry-list {
    li {
      margin-bottom: 1rem;
    }
  }
  .bm_entry-title {
    font-size: 1.25rem;
  }
  .bm_entry-body {
    margin-bottom: 1rem;
  }
}
// 応募方法 04
.bm_entry-04 {
  .bm_entry-list {
    li {
      margin-bottom: 1rem;
    }
  }
  .bm_entry-title {
    font-size: 1.25rem;
  }
  .bm_entry-body {
    margin-bottom: 1rem;
  }
}

// --------------------------------------------------
// BM ダウンロードブロック用記述
// --------------------------------------------------
section {
  overflow: hidden;
}

// firstview
.dlb_firstview-center {
  background-position: 50% 50%;
  .container {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}
.dlb_firstview-left {
  background-position: 50% 50%;
  .container {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}
.dlb_firstview-right {
  background-position: 50% 50%;
  .container {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}
.dlb_firstview-items {
  .container {
    // padding-top: 48px;
    // padding-bottom: 48px;
  }
  .dlb_container-text {
    padding-top: 3rem;
    padding-bottom: 11.5rem;
  }
  .dlb_container-items {
    margin-top: -10rem;
    .col {
      @include media-breakpoint-down(sm) {
        padding-left: 2rem;
        padding-right: 2rem;
        min-width: 300px;
        height: 85vw;
        margin-top: 2rem;
        &:first-child {
          margin-top: 0;
        }
        img {
          width: 80px;
          height: auto;
        }
      }
      @include media-breakpoint-up(sm) {
        height: 350px;
      }
      @include media-breakpoint-up(md) {
        height: 350px;
      }
      @include media-breakpoint-up(lg) {
        height: 300px;
      }
      @include media-breakpoint-up(xl) {
        height: 350px;
      }
    }
  }
}
.dlb_firstview-text {
  overflow: visible;
  position: relative;
  @include media-breakpoint-up(md) {
    margin-top: 106px;
    margin-bottom: 48px;
  }
  @include media-breakpoint-up(md) {
    margin-top: 130px;
    margin-bottom: 72px;
  }
  &:before {
    content: '';
    width: 60%;
    height: 360px;
    background-color: #ddd;
    position: absolute;
    top: calc(50% - 180px);
    left: 0;
    z-index: -2;
    @include media-breakpoint-down(md) {
      width: 55%;
    }
    @include media-breakpoint-down(sm) {
      width: 80%;
    }
  }
  .bg-img {
    background-position: 50% 50%;
    width: 60%;
    height: 360px;
    background-color: #f5f4f2;
    position: absolute;
    top: calc(50% - 230px);
    right: 0;
    z-index: -1;
    @include media-breakpoint-down(md) {
      width: 55%;
    }
    @include media-breakpoint-down(sm) {
      position: relative;
      width: 100%;
      height: 240px;
      top: 0;
      margin-left: 15px;
      margin-right: 15px;
    }
  }
  .container {
  }
}

// title
.dlb_title-h1 {
  font-weight: bold;
}
.dlb_title-h2 {
  font-weight: bold;
}
.dlb_title-h3 {
  font-weight: bold;
}
.dlb_title-h4 {
  font-weight: bold;
}
.dlb_title-h5 {
  font-weight: bold;
}
.dlb_title-h6 {
  font-weight: bold;
}

// text
.dlb_text {
  .dlb_text-copy {
    line-height: 1;
  }
}
.dlb_text_wrap {
  .container {
    // padding: 48px;
    @include media-breakpoint-up(sm) {
      max-width: 510px;
    }
    @include media-breakpoint-up(md) {
      max-width: 690px;
    }
    @include media-breakpoint-up(lg) {
      max-width: 930px;
    }
    @include media-breakpoint-up(xl) {
      max-width: 1120px;
    }
  }
  .dlb_text_wrap-title {
    position: relative;
    margin-top: -44px;
    bottom: 24px;
    line-height: 1.7;
    @include media-breakpoint-down(sm) {
      // margin-top: -33px;
      margin-top: -28px;
      bottom: 12px;
    }
    * {
      display: inline;
      padding: 8px;
      line-height: 1.5;
    }
  }
  p:last-child {
    margin-bottom: 0;
  }
}
.dlb_text_colum-1 {
}
.dlb_text_colum-2 {
}

// contests
.dlb_contents_float {
  figure {
    max-width: 80%;
  }
  figcaption {
    @include media-breakpoint-up(sm) {
      max-width: 290px;
    }
  }
}
.dlb_contents_image-left {
}
.dlb_contents_image-right {
  // .dlb_contents_bg_text {
  //   position: relative;
  //   z-index: 1;
  //   @include media-breakpoint-down(md) {
  //     position: static;
  //   }
  // }
  // .dlb_contents_bg_image {
  //   margin-right: auto;
  //   @include media-breakpoint-down(md) {
  //     margin-left: -12rem;
  //   }
  // }
}
.dlb_contents_column-1 {
}
.dlb_contents_bg_position-right {
  .dlb_contents_bg_text {
    position: relative;
    z-index: 1;
    @include media-breakpoint-down(md) {
      position: static;
    }
  }
  .dlb_contents_bg_image {
    margin-right: auto;
    @include media-breakpoint-up(lg) {
      margin-left: -12rem;
    }
  }
}
.dlb_contents_bg_position-left {
  .row {
   -webkit-box-orient: horizontal;
   -webkit-box-direction: reverse;
   -ms-flex-direction: row-reverse;
   flex-direction: row-reverse;
 }
 .dlb_contents_bg_text {
   position: relative;
   z-index: 1;
   @include media-breakpoint-down(md) {
     position: static;
   }
 }
 .dlb_contents_bg_image {
   margin-left: auto;
   @include media-breakpoint-up(lg) {
     margin-right: -12rem;
   }
  }
}
.dlb_contents_tab {
}
.dlb_contents_wrap-narrow {
}
.dlb_contents_wrap-wide {
  background-position: center center;
  background-repeat: no-repeat;
  .dlb_contents_wrap-text {
    padding-top: 24px;
    padding-bottom: 24px;
    background: rgba(0, 0, 0, 0.3);
  }
  .row {
    @include media-breakpoint-down(md) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}
.dlb_contents_wrap-double {
  .dlb_contents_wrap-title {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .dlb_contents_wrap-data {
    margin-top: -10rem;
    border-radius: 20px;
    .accodion-link {
      position: relative;
      border: none;
      outline: none;
      background-color: transparent;
      background-position: 0 0;
      background-repeat: no-repeat;
      width: 100%;
      text-align: left;
      margin: 20px 0 6px;
      padding: 0 0 0 40px;
      color: #333;
      &:hover,
      &:active,
      &:focus {
        outline: none;
      }
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        display: inline-block;
        width: 24px;
        height: 24px;
        background-color: #333;
        border: 1px solid #333;
        -webkit-transition: 0.3s ease;
        transition: 0.3s ease;
        border-radius: 50%;
        vertical-align: bottom;
      }
      &:after {
        content: "";
        position: relative;
        top: 50%;
        margin-top: 2px;
        display: inline-block;
        width: 8px;
        height: 8px;
        min-width: 8px;
        margin-right: 0.5rem;
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        position: absolute;
        top: 11px;
        left: 8px;
        margin-right: 0;
        -webkit-transition: 0.3s ease;
        transition: 0.3s ease;
        -webkit-transform-origin: center center 0;
        transform-origin: center center 0;
      }
      &.collapsed {
        &::before {
          background-color: #fff;
          border: 1px solid #333;
        }
        &::after {
          -webkit-transform: rotate(-225deg);
          transform: rotate(-225deg);
          top: 8px;
          left: 8px;
          border-color: #333;
        }
      }
    }
  }
}
.dlb_contents_column-2 {
}
.dlb_contents_column-3 {
}
.dlb_contents_column-4 {
}
.dlb_contents_column-5 {
}
.dlb_contents_modal_column-2 {
  .modal-btn {
    a {
      position: relative;
      padding-right: 2rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &::after {
        content: '+';
        display: block;
        position: absolute;
        right: 10px;
        top: 0;
        font-size: 1.5rem;
        color: inherit;
      }
    }
  }
  .modal::before {
    content: '×';
    width: 48px;
    height: 48px;
    display: block;
    background: #000;
    color: #fff;
    line-height: 42px;
    text-align: center;
    font-size: 2rem;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  .modal.show::before {
    opacity: 1;
  }
}
.modal-dialog {
  @include media-breakpoint-up(md) {
    max-width: 980px;
    margin: 1.75rem auto;
  }
}

.dlb_contents_info {
  .container {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .dlb_contents_info-item {
    transition: transform 0.3s ease;
    &:hover {
      transform: scale(1.08);
    }
  }
  .row {
    margin: 0 -10px;
    & > * {
      padding: 0 10px;
    }
  }
  // フォントサイズ調整
  @include media-breakpoint-down(sm) {
    .h4 {
      font-size: 0.725rem;
    }
    .dlb_title-h3 {
      font-size: 1rem;
    }
  }
}
.dlb_contents_qa {
  .dlb_contents_qa-title {
    max-width: 65px !important;
    padding-right: 0;
    @include media-breakpoint-down(sm) {
      max-width: 45px !important;
    }
  }
  .dlb_contents_qa-q {
    display: flex;
    width: 50px;
    height: 50px;
    font-size: 1.5rem;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    position: relative;
    @include media-breakpoint-down(sm) {
      width: 30px;
      height: 30px;
      font-size: 1.25rem;
    }
  }
  .dlb_contents_qa-a {
    display: flex;
    width: 50px;
    height: 50px;
    font-size: 1.5rem;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    position: relative;
    @include media-breakpoint-down(sm) {
      width: 30px;
      height: 30px;
      font-size: 1.25rem;
      padding: 0;
      line-height: 28px;
    }
  }

  // .dlb_contents_qa-title {
  //   @include media-breakpoint-down(sm) {
  //     max-width: 45px !important;
  //   }
  // }
  // .dlb_contents_qa-q {
  //   padding-top: 8px;
  //   padding-bottom: 16px;
  //   text-align: center;
  //   font-size: 32px;
  //   @include media-breakpoint-down(sm) {
  //     width: 30px;
  //     height: 30px;
  //     font-size: 1.25rem;
  //     padding: 0;
  //     line-height: 28px;
  //   }
  // }
  // .dlb_contents_qa-a {
  //   padding-top: 8px;
  //   padding-bottom: 16px;
  //   text-align: center;
  //   font-size: 32px;
  //   @include media-breakpoint-down(sm) {
  //     width: 30px;
  //     height: 30px;
  //     font-size: 1.25rem;
  //     padding: 0;
  //     line-height: 28px;
  //   }
  // }
}
.dlb_contents_table {
}
.dlb_contents_boxlink-icon {
  .dlb_contents_boxlink-item {
    background-color: #eee;
    .dlb_title-h3 {
      font-weight: bold;
      @include media-breakpoint-down(md) {
        font-weight: normal !important;
      }
    }
    &:nth-child(8n + 1) {
      background-color: #ddd;
    }
    &:nth-child(8n + 3) {
      background-color: #ddd;
    }
    &:nth-child(8n + 6) {
      background-color: #ddd;
    }
    &:nth-child(8n + 8) {
      background-color: #ddd;
    }
    @include media-breakpoint-down(sm) {
      &:nth-child(8n + 3) {
        background-color: #eee;
      }
      &:nth-child(8n + 4) {
        background-color: #ddd;
      }
      &:nth-child(8n + 5) {
        background-color: #ddd;
      }
      &:nth-child(8n + 6) {
        background-color: #eee;
      }
    }
    img {
      transition: transform 0.3s ease;
    }
    &:hover img {
      transform: scale(1.08);
    }
  }
}
.dlb_contents_boxlink-image {
  .dlb_contents_boxlink-data {
    position: relative;
    p:last-child {
      margin-bottom: 0;
    }
  }
  .dlb_contents_boxlink-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  .dlb_contents_boxlink-link {
    text-align: right;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
}
.dlb_contents_box_column-2 {
  .dlb_contents_box-title {
    border-bottom: 1px solid #007bff;
    color: #007bff;
    text-align: center;
    padding-bottom: 16px;
    @include media-breakpoint-up(sm) {
      border-bottom: none;
      border-right: 1px solid #007bff;
      padding-bottom: 0;
    }
  }
  .dlb_contents_box-data {
  }
}
.dlb_contents_box_column-1 {
}
.dlb_contents_box_bg {
  .dlb_contents_box_bg-item {
    background-color: #eee;
    &:nth-child(4n + 1) {
      background-color: #ddd;
    }
    &:nth-child(4n + 4) {
      background-color: #ddd;
    }
    .dlb_contents_box_bg-badge {
      min-width: 6rem;
      max-width: 6rem;
      flex: 1 0 auto;
      .badge {
        margin-top: 0.25rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    @include media-breakpoint-down(md) {
      &:nth-child(4n + 3) {
        background-color: #ddd;
      }
      &:nth-child(4n + 4) {
        background-color: #eee;
      }
    }
    @include media-breakpoint-down(sm) {
      &:nth-child(4n + 3) {
        background-color: #ddd;
      }
      &:nth-child(4n + 4) {
        background-color: #eee;
      }
    }
  }
}
.dlb_contents_book {
  .dlb_contents-image {
    position: relative;
    .new {
      position: absolute;
      // top: -15px;
      // left: -15px;
      border-radius: 50%;
      background-color: #ca062b;
      // padding-top: 13px;
      // width: 50px;
      // height: 50px;
      text-align: center;
      color: #fff;
      top: -10px;
      left: 0px;
      padding-top: 10px;
      width: 40px;
      height: 40px;
      font-size: 0.75rem;
      @include media-breakpoint-down(sm) {
        top: -0.25rem;
        left: 0.25rem;
      }
    }
  }
  .dlb_contents-title {
    // height: 4.5rem;
    @include media-breakpoint-down(sm) {
      // height: auto;
    }
  }
}

.dlb_contents_wrap-single {
  .dlb_contents_wrap-bg {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    height: 480px;
    margin-bottom: -10rem;
    @include media-breakpoint-down(md) {
      height: 316px;
      margin-bottom: -6rem;
    }
    @include media-breakpoint-down(sm) {
      height: 148px;
      margin-bottom: -3rem;
    }
  }
}
.dlb_contents_wide-2 {
  .dlb_contents_wide-col {
    background-position: 50% 50%;
    & + .dlb_contents_wide-col {
      border-left: 1px solid #fff;
      @include media-breakpoint-down(sm) {
        border-left: none;
        border-top: 1px solid #fff;
      }
    }
  }
  .dlb_contents_wide-title {
    position: relative;
    margin-bottom: 3rem;
    &:after {
      content: '';
      position: absolute;
      bottom: -1rem;
      left: 50%;
      margin-left: -1rem;
      width: 2rem;
      height: 2px;
      background-color: #ffffff;
    }
  }
}
.row-cols-md-2 {
}

.dlb_contents_wide-4 {
}

.dlb_contents_card-3 {
}

.dlb_contents_read {
}

.dlb_contents_card-2 {
  .dlb_contents_card-item {
    .shadow {
      position: relative;
      padding-bottom: 4rem;
    }
  }
  .dlb_contents_card-btn {
    width: 50%;
    position: absolute;
    bottom: 1.5rem;
    right: 1.5rem;
    @include media-breakpoint-down(md) {
      width: auto;
      left: 1.5rem;
    }
  }
}
.dlb_contents_overflow {
  position: relative;
  .num {
    display: inline-block;
    padding: 8px 12px;
    margin-right: 1rem;
  }
  .icon {
    // flex:;
    max-width: 60px;
    margin-right: 15px;
  }
  // 右
  .dlb_contents_overflow-right {
    min-height: 360px;
    &:before {
      content: '';
      width: 60%;
      height: 100%;
      background-color: #f5f4f2;
      position: absolute;
      top: 0;
      right: 0;
      z-index: -2;
      @include media-breakpoint-up(md) {
        min-height: 330px;
        top: 50% !important;
        transform: translate(0, -50%);
      }
      @include media-breakpoint-up(lg) {
        min-height: 360px;
      }
      @include media-breakpoint-down(md) {
        width: 65%;
      }
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
    .dlb_contents_overflow-image {
      @include media-breakpoint-down(sm) {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
    .dlb_contents_overflow-data {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      @include media-breakpoint-down(sm) {
        margin-top: -3rem;
        padding-top: 4rem;
        padding-bottom: 1.5rem;
        background-color: #f5f4f2;
        z-index: -1;
      }
    }
  }
  // 左
  .dlb_contents_overflow-left {
    min-height: 360px;
    &:before {
      content: '';
      width: 60%;
      height: 100%;
      background-color: #f5f4f2;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -2;
      @include media-breakpoint-up(md) {
        min-height: 330px;
        top: 50% !important;
        transform: translate(0, -50%);
      }
      @include media-breakpoint-up(lg) {
        min-height: 360px;
      }
      @include media-breakpoint-down(md) {
        width: 65%;
      }
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
    .dlb_contents_overflow-image {
      @include media-breakpoint-down(sm) {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
    .dlb_contents_overflow-data {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      @include media-breakpoint-down(sm) {
        margin-top: -3rem;
        padding-top: 4rem;
        padding-bottom: 1.5rem;
        background-color: #f5f4f2;
        z-index: -1;
      }
    }
  }
}
.dlb_indent-link {
  padding-left: 1rem;
  text-indent: -0.8rem;
}

// slide
.dlb_slide-1 {
}
.dlb_slide-2 {
  .carousel-control-prev {
    a {
      display: flex;
      width: 100%;
      height: 100%;
      text-align: center;
      align-items: center;
    }
    .carousel-control-prev-icon {
      flex: 1 0 auto;
      background-size: contain;
    }
  }
  .carousel-control-next {
    a {
      display: flex;
      width: 100%;
      height: 100%;
      text-align: center;
      align-items: center;
    }
    .carousel-control-next-icon {
      flex: 1 0 auto;
      background-size: contain;
    }
  }
  .dlb_carousel-thumb {
    margin: 0 -5px;
    .col {
      padding: 0 5px;
    }
  }
}
.dlb_slide-3 {
  .container {
    position: relative;
  }
  .dlb_slide-prev {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    // padding: 8px;
    &::before {
      @include btn-arrow($size: 10px, $top: 50%, $border-width: 4px, $border-color: #000000, $rotate: 225deg);
      display: block;
      margin-top: -8px;
    }
  }
  .dlb_slide-next {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    // padding: 8px;
    &::before {
      @include btn-arrow($size: 10px, $top: 50%, $border-width: 4px, $border-color: #000000, $rotate: 45deg);
      display: block;
      margin-top: -8px;
    }
  }
}
.dlb_slide-4 {
  .carousel-control-prev {
    a {
      display: flex;
      width: 100%;
      height: 100%;
      text-align: center;
      align-items: center;
    }
    .carousel-control-prev-icon {
      flex: 1 0 auto;
      background-size: contain;
    }
  }
  .carousel-control-next {
    a {
      display: flex;
      width: 100%;
      height: 100%;
      text-align: center;
      align-items: center;
    }
    .carousel-control-next-icon {
      flex: 1 0 auto;
      background-size: contain;
    }
  }
  .dlb_slide-data {
    .dlb_slide-item {
      line-height: 1.5;
    }
  }
  .dlb_slide_list {
    padding: 0 15px;
    .row {
      margin: 0 -5px;
      & > * {
        padding: 0 5px;
      }
    }
  }
  .dlb_slide-image {
    .dlb_slide_list {
    }
  }
}
.dlb_slide-5 {
  .container {
    position: relative;
  }
  .dlb_slide-prev {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    // padding: 8px;
    &::before {
      @include btn-arrow($size: 10px, $top: 50%, $border-width: 4px, $border-color: #000000, $rotate: 225deg);
      display: block;
      margin-top: -10px;
    }
  }
  .dlb_slide-next {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    // padding: 8px;
    &::before {
      @include btn-arrow($size: 10px, $top: 50%, $border-width: 4px, $border-color: #000000, $rotate: 45deg);
      display: block;
      margin-top: -10px;
    }
  }
}

// flow
.dlb_flow-1 {
  .dlb_flow-item {
    position: relative;
    & + .dlb_flow-item {
      &::before {
        content: '';
        position: absolute;
        top: 47%;
        left: -10px;
        display: block;
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 25px 0 25px 20px;
        border-color: transparent transparent transparent #ffc732;
        @include media-breakpoint-down(md) {
          top: -25px;
          left: 49%;
          transform: rotate(90deg);
        }
      }
    }
    // display: flex;
    // flex-flow: column;
  }
  .dlb_flow-title {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .dlb_flow-inner {
    display: block;
    flex: 1 0 auto;
    max-width: 100%;
  }
}

.dlb_flow-2 {
  .dlb_flow-item {
    position: relative;
    & + .dlb_flow-item {
      margin-top: 48px;
      .dlb_flow-title::before {
        content: '';
        position: absolute;
        top: -35px;
        left: 50%;
        display: block;
        width: 0px;
        height: 0px;
        margin-left: -25px;
        border-style: solid;
        border-width: 20px 25px 0 25px;
        border-color: #ffc732 transparent transparent transparent;
      }
      @include media-breakpoint-down(sm) {
        position: static;
      }
    }
    @include media-breakpoint-down(sm) {
      .dlb_flow-title > .row {
        padding: 16px;
      }
    }
    @include media-breakpoint-up(sm) {
      .dlb_flow-data {
        border: none !important;
      }
    }
  }
}

.dlb_flow-3 {
  .dlb_flow-item {
    position: relative;
    margin-bottom: 48px;
    .dlb_flow-title::before {
      content: '';
      position: absolute;
      bottom: -35px;
      left: 0;
      display: block;
      width: 100%;
      height: 35px;
      background: linear-gradient(to bottom left, #007bff 49%, transparent 50%) top left/ 50% 100% no-repeat,
        linear-gradient(to bottom right, #007bff 49%, transparent 50%) top right / 50% 100% no-repeat;
    }

    @include media-breakpoint-down(sm) {
      .dlb_flow-title > .row {
        padding: 16px;
      }
    }
    @include media-breakpoint-up(sm) {
      .dlb_flow-data {
        border: none !important;
      }
    }
  }
}

.dlb_flow-4 {
  .dlb_flow-item {
    position: relative;
    & + .dlb_flow-item {
      margin-top: 48px;
      .dlb_flow-title::before {
        content: '';
        position: absolute;
        top: -35px;
        left: 50%;
        display: block;
        width: 0px;
        height: 0px;
        margin-left: -25px;
        border-style: solid;
        border-width: 20px 25px 0 25px;
        border-color: #ffc732 transparent transparent transparent;
      }
      @include media-breakpoint-down(sm) {
        position: static;
      }
    }
    @include media-breakpoint-down(sm) {
      .dlb_flow-title > .row {
        padding: 16px;
      }
    }
    @include media-breakpoint-up(sm) {
      .dlb_flow-data {
        border-color: #dee2e6 !important;
      }
    }
  }
}
.dlb_flow-5 {
  .dlb_flow-item {
    position: relative;
    border-width: 4px !important;
    border-radius: 10px;
    & + .dlb_flow-item {
      margin-top: 48px;
      &::before {
        content: '';
        position: absolute;
        top: -35px;
        left: 50%;
        display: block;
        width: 0px;
        height: 0px;
        margin-left: -25px;
        border-style: solid;
        border-width: 20px 25px 0 25px;
        border-color: #777777 transparent transparent transparent;
      }
    }
  }
  .dlb_flow-title {
    position: relative;
    padding-left: 90px;
    margin-bottom: 1rem;
    @include media-breakpoint-down(sm) {
      padding-left: 0;
    }
  }
  .dlb_flow-label {
    display: inline-block;
    position: absolute;
    top: 0px;
    left: -34px;
    padding: 0.5rem 1.5rem;
    margin-right: -24px;
    background-color: #777777;
    color: #fff;
    &:before {
      position: absolute;
      content: '';
      left: 0;
      bottom: -8px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 6px 8px 0;
      border-color: transparent #555555 transparent transparent;
    }
  }
}

// list
.dlb_list-disc {
}
.dlb_list-decimal {
}
.dlb_list-image {
}
.dlb_list_accordion {
  .btn {
    position: relative;
    color: inherit !important;
    padding-right: 40px !important;
    &::after {
      @include btn-arrow($size: 8px, $top: 50%, $border-width: 2px, $border-color: #000000, $rotate: 315deg);
      position: absolute;
      right: 16px;
      margin-top: -6px;
      transition: transform 0.3s ease;
      transform-origin: 50% 50% 0;
    }
    &.collapsed {
      &::after {
        transform: rotate(135deg);
      }
    }
  }
}

// table
.dlb_table_row {
  .table th {
    background-image: none;
  }
  .dlb_table_row-title {
    width: 100%;
    @include media-breakpoint-up(md) {
      width: 25%;
    }
  }
}
.dlb_table_row-striped {
  .table th {
    background-image: none;
  }
  .dlb_table_row-title {
    width: 100%;
    @include media-breakpoint-up(md) {
      width: 25%;
    }
  }
}
.dlb_table_column {
  .table th {
    background-image: none;
  }
}
.dlb_table_column-striped {
  .table th {
    background-image: none;
  }
}
.dlb_table_stack {
  .dlb_table_stack-data {
    border: 1px solid #dee2e6;
    @include media-breakpoint-up(md) {
      border: none;
    }
  }
}

// btn
.dlb_btn_column-3 {
  .container {
    padding-right: 16px;
    padding-left: 16px;
  }
  .row {
    margin-right: -8px;
    margin-left: -8px;
    .col {
      padding-right: 8px;
      padding-left: 8px;
    }
  }
  .d-table {
    a {
      display: table-cell;
    }
  }
}
.dlb_btn_column-4 {
  .container {
    padding-right: 16px;
    padding-left: 16px;
  }
  .row {
    margin-right: -8px;
    margin-left: -8px;
    .col {
      padding-right: 8px;
      padding-left: 8px;
    }
  }
  .d-table {
    a {
      display: table-cell;
    }
  }
}
.dlb_btn_column-5 {
  .container {
    padding-right: 16px;
    padding-left: 16px;
  }
  .row {
    margin-right: -8px;
    margin-left: -8px;
    .col {
      padding-right: 8px;
      padding-left: 8px;
    }
  }
  .d-table {
    a {
      display: table-cell;
    }
  }
}
.dlb_btn_image_column-5 {
}
.dlb_btn_link_column-4 {
  .dlb_btn-item {
    transition: box-shadow 0.2s ease;
    &:hover {
      box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
    }
  }
}
.dlb_btn_link_column-3 {
  .dlb_btn-item {
    position: relative;
    transition: box-shadow 0.2s ease;
    .row > [class*='col-'] {
      position: static;
    }
    &:hover {
      box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
    }
  }
}
.dlb_btn_card_column-3 {
  .shadow {
    transition: box-shadow 0.3s ease;
    &:hover {
      box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.3) !important;
    }
    a {
      text-decoration: none;
    }
  }
}
.dlb_btn_link_list {
}
.dlb_btn_card_column-4 {
  overflow: unset;
  // .shadow {
  //   transition: box-shadow .3s ease;
  //   &:hover {
  //     // box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
  //     box-shadow: 0 .5rem 1rem rgba(0,0,0,.175) !important;
  //   }
  // }
  @include media-breakpoint-up(md) {
    .row {
      margin-right: -8px;
      margin-left: -8px;
    }
    .col {
      padding-right: 8px;
      padding-left: 8px;
    }
  }
  @include media-breakpoint-up(lg) {
    .row {
      margin-right: -15px;
      margin-left: -15px;
    }
    .col {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
}
.dlb_btn_link-text {
  overflow: unset;
  .stretched-link {
    // 縦揃え用記述
    height: 100%;
    a {
      // 縦揃え用記述
      height: 100%;
      display: flex;
      align-items: center;
    }
    img {
      margin-right: 0.5rem;
    }
    span {
    }
  }
  // .shadow {
  //   transition: box-shadow .3s ease;
  //   &:hover {
  //     // box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
  //     box-shadow: 0 .5rem 1rem rgba(0,0,0,.175) !important;
  //   }
  // }
}

// blog
.dlb_blog_column-4 {
  .container {
    position: relative;
  }
  .dlb_blog-item {
    padding: 1rem;
    a {
      color: inherit;
    }
    &.stretched-link {
      a {
        position: relative;
        display: block;
        &::after {
          right: 0;
          left: 0;
        }
      }
    }
  }
  .dlb_blog-image {
  }
  .dlb_blog-data {
    position: relative;
    .dlb_title-h3 {
      font-size: 1.25rem;
      max-height: 3.7rem;
      overflow: hidden;
    }
    p {
      margin-bottom: 0;
      max-height: 6rem;
      overflow: hidden;
    }
  }
  .dlb_blog-badge {
    position: absolute;
    top: -32px;
    right: 0;
    text-align: right;
  }
  // siema prev/next buttons
  .dlb_slide-prev {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    // padding: 8px;
    &::before {
      @include btn-arrow($size: 10px, $top: 50%, $border-width: 4px, $border-color: #000000, $rotate: 225deg);
      display: block;
      margin-top: -10px;
      left: 10px;
    }
  }
  .dlb_slide-next {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    // padding: 8px;
    &::before {
      @include btn-arrow($size: 10px, $top: 50%, $border-width: 4px, $border-color: #000000, $rotate: 45deg);
      display: block;
      margin-top: -10px;
      left: -3px;
    }
  }
}
.dlb_blog_column_image-left {
  .dlb_blog-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 1.5rem;
    color: inherit;
    .dlb_title-h3 {
      font-size: 1.25rem;
      max-height: 3.7rem;
      overflow: hidden;
    }
    // p {
    //   max-height: 6rem;
    //   overflow: hidden;
    // }
    &:first-child {
      margin-top: 0;
    }
    @media (min-width: 768px) {
      &:nth-child(2) {
        margin-top: 0;
      }
    }
  }
  .dlb_blog-image {
    // col import
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    // col-5 import
    flex: 0 0 50%;
    max-width: 50%;
    @include media-breakpoint-down(sm) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .dlb_blog-data {
    // col import
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    // col-5 import
    flex: 0 0 50%;
    max-width: 50%;
    @include media-breakpoint-down(md) {
      padding-left: 0;
    }
    @include media-breakpoint-down(sm) {
      flex: 0 0 100%;
      max-width: 100%;
      padding-left: 15px;
    }
  }
  .dlb_blog-badge {
  }
}
// news
.rss_new,
.rss_news {
  font-size: 90%;
  padding: 0.25rem 0.5rem;
  text-align: center;
}
.dlb_news .rss_new,
.dlb_news .rss_news,
.dlb_news-body .rss_new,
.dlb_news-body .rss_news,
.dlb_news_pickup .rss_new,
.dlb_news_pickup .rss_news,
.dlb_news_tab .rss_new,
.dlb_news_tab .rss_news,
.dlb_news-list .rss_new,
.dlb_news-list .rss_news,
.dlb_news_column .rss_new,
.dlb_news_column .rss_news,
.dlb_news_blog .rss_new,
.dlb_news_blog .rss_news {
  margin-right: 0.5rem;
  max-width: 20%;
  min-width: 6rem;
  @include media-breakpoint-down(sm) {
    position: absolute;
    top: -32px;
    left: 100px;
    max-width: 60%;
    max-width: calc(100% - 100px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.dlb_news_boxlink .rss_new,
.dlb_news_boxlink .rss_news {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 0;
}
.list-group-item {
  overflow: auto;
}
.dlb_news {
  dl,
  dd {
    margin-bottom: 0;
  }
  dt {
    font-weight: normal;
  }
  .rss_date {
    flex: 0 0 8rem;
    text-align: left;
    padding: 0 15px;
    @include media-breakpoint-up(sm) {
      text-align: center;
    }
  }
  .rss_title {
    a {
      display: flex;
      // align-items: center;
      align-items: baseline;
    }
  }
  .rss_contents {
    display: none;
    margin-top: 0.5rem;
    padding-top: 0;
    padding-left: 8rem !important;
    // padding-left: 17.2rem !important;
    box-sizing: border-box;
    &:empty {
      margin-top: 0;
    }
    @include media-breakpoint-down(sm) {
      padding-left: 0 !important;
    }
  }
  .dlb_title-h3 {
    position: relative;
    .btn {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}
.dlb_news-body {
  .rss_contents {
    display: block !important;
  }
}
.dlb_news_pickup {
  dl,
  dd {
    margin-bottom: 0;
  }
  dt {
    font-weight: normal;
  }
  .rss_date {
    flex: 0 0 8rem;
    text-align: left;
    padding: 0 15px;
    @include media-breakpoint-up(sm) {
      text-align: center;
    }
  }
  .rss_title {
    a {
      display: flex;
      align-items: center;
    }
  }
  .rss_contents {
    display: none;
    margin-top: 0.5rem;
    padding-top: 0;
    padding-left: 8rem !important;
    box-sizing: border-box;
    @include media-breakpoint-down(sm) {
      padding-left: 0 !important;
    }
  }
  .dlb_title-h3 {
    position: relative;
    .btn {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .dlb_news_pickup-figure {
    position: relative;
    figcaption {
      // position: absolute;
      width: 100%;
      padding: 8px;
      // left: 0;
      // bottom: 0;
      text-align: center;
      background-color: rgba(0, 0, 0, 0.7);
      color: #fff;
    }
  }
  .rss_contents {
    display: none;
    margin-top: 0.5rem;
    padding-top: 0;
    padding-left: 8rem !important;
    box-sizing: border-box;
    @include media-breakpoint-down(sm) {
      padding-left: 0 !important;
    }
  }
}
.dlb_news_tab {
  .rss_date {
    flex: 0 0 8rem;
    text-align: left;
    padding: 0 15px;
    @include media-breakpoint-up(sm) {
      text-align: center;
    }
  }
  .rss_title {
    a {
      display: flex;
      align-items: center;
    }
  }
  .rss_contents {
    display: none;
    margin-top: 0.5rem;
    padding-top: 0;
    padding-left: 8rem !important;
    box-sizing: border-box;
    @include media-breakpoint-down(sm) {
      padding-left: 0 !important;
    }
  }
  .dlb_title-h3 {
    position: relative;
    .btn {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}
.dlb_news_boxlink {
  dl,
  dd {
    margin-bottom: 0;
  }
  dt {
    font-weight: normal;
  }
  .dlb_news_boxlink-item {
    position: relative;
    padding: 32px 24px;
    border: 0.5rem solid #fff !important;
    .badge {
      position: absolute;
      top: 0;
      left: 0;
      padding: 8px;
    }
  }

  .dlb_news-list {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    margin-top: 0;
    margin-bottom: 0;
    align-items: stretch !important;
    padding: 15px !important;

    & > * {
      flex: 0 0 100% !important;
      max-width: 100% !important;
      @include media-breakpoint-up(md) {
        flex: 0 0 50% !important;
        max-width: 50% !important;
      }
      @include media-breakpoint-up(lg) {
        flex: 0 0 25% !important;
        max-width: 25% !important;
      }
    }
  }
  .list-group-item {
    display: block !important;
    // bg-light
    background-color: #f8f9fa !important;
    // style
    position: relative;
    padding: 32px 24px !important;
    border: 0.5rem solid #fff !important;
    .badge {
      position: absolute;
      top: 0;
      left: 0;
      padding: 8px;
      border-radius: 0;
    }
  }
  .rss_date {
    position: static;
  }
  .rss_title {
    position: static;
    display: block;
    margin-bottom: 0;
  }
  .rss_contents {
    display: none;
  }
}

.dlb_news-list {
  dl,
  dd {
    margin-bottom: 0;
  }
  dt {
    font-weight: normal;
  }
  .rss_title {
    a {
      vertical-align: middle;
    }
    span {
      vertical-align: baseline;
    }
  }
  .list-group-item {
    border-right: none;
    border-left: none;
  }
}
.dlb_news_column {
  .rss_title {
    a {
      display: flex;
      align-items: flex-start;
      span {
        // flex: 1 0 1rem;
        // max-width: 7rem;
        flex: 0 0 auto;
        max-width: 7rem;
        margin-right: 1rem;
      }
    }
  }
  .rss_date {
    flex: 0 0 100%;
  }
  .rss_title {
    flex: 0 0 100%;
  }
  .rss_contents {
    display: none;
  }
}
.dlb_news_blog {
  dl,
  dd {
    margin-bottom: 0;
  }
  dt {
    font-weight: normal;
  }
  .dlb_title-h3 {
    position: relative;
    .btn {
      position: absolute;
      right: 0;
      top: 0;
      @include media-breakpoint-down(sm) {
        position: static;
        display: block;
        margin: 1rem auto 0;
        max-width: 80%;
      }
    }
  }
  .dlb_news_pickup-figure {
    position: relative;
    figcaption {
      // position: absolute;
      width: 100%;
      padding: 8px;
      // left: 0;
      // bottom: 0;
      text-align: center;
      background-color: rgba(0, 0, 0, 0.7);
      color: #fff;
    }
  }
  .dlb_news-list {
    .list-group-item:first-child {
      border-top: none;
    }
    .rss_title {
      a {
        display: flex;
        align-items: flex-start;
        span {
          flex: 1 0 1rem;
          max-width: 7rem;
          margin-right: 1rem;
        }
      }
    }
    .rss_date {
      flex: 0 0 7rem;
      margin-bottom: 0.5rem;
    }
    .rss_contents {
      display: none;
    }
    .dlb_news-list {
    }
    .dlb_blog-item {
      padding: 1rem;
      position: relative;
      & + .dlb_blog-item {
        border-top: 1px solid #ccc;
      }
    }
    .dlb_blog-image {
      display: none;
    }
    .dlb_blog-data {
      span {
        padding-right: 7rem;
        margin-bottom: 0.75rem;
      }
      h3 {
        font-size: 1.25rem;
        margin-bottom: 0.5rem;
      }
      p {
        margin-bottom: 0;
      }
      div {
        position: absolute;
        top: 0.9rem;
        right: 1rem;
        // max-width: 7rem;
        // text-overflow: ellipsis;
        // width: 7rem;
        // overflow: hidden;
      }
    }
    .dlb_blog-badge {
    }
    // .rss_date {
    //   padding-right: 7rem;
    //   margin-bottom: .5rem;
    // }
    // .rss_title {
    //   a {
    //     display: flex;
    //   }
    //   span {
    //     position: absolute;
    //     top: .9rem;
    //     right: 1rem;
    //   }
    // }
    // .rss_body {
    //   // margin-bottom: 1rem;
    // }
  }
  .dlb_news-blog {
    padding-left: 1.5rem;
    p .dlb_title-h3 {
      position: relative;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      border-bottom: 2px solid #ccc;
      .btn {
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    .list-group-item {
      position: relative;

      border: none;
      & + .list-group-item {
        border-top: 1px solid #ccc;
      }
    }
    .rss_date {
      padding-right: 7rem;
      margin-bottom: 0.5rem;
    }
    .rss_title {
      a {
        display: flex;
      }
      span {
        position: absolute;
        top: 0.9rem;
        right: 1rem;
      }
    }
    .rss_body {
      // margin-bottom: 1rem;
    }
  }
}

// contact
.dlb_contact_column {
  background-position: 50% 50%;
  background-size: cover;
  margin-top: 48px;
  margin-bottom: 48px;
  .dlb_contact-tel {
    font-size: 3.5rem;
    font-weight: bold;
    @include media-breakpoint-down(md) {
      font-size: 2rem;
    }
    @include media-breakpoint-down(sm) {
      font-size: 2rem;
    }
  }
}
.dlb_contact_rows {
  margin-top: 48px;
  margin-bottom: 48px;
  .dlb_contact-tel {
    font-size: 3.5rem;
    font-weight: bold;
    @include media-breakpoint-down(md) {
      font-size: 3rem;
    }
    @include media-breakpoint-down(sm) {
      font-size: 2rem;
    }
  }
}
.dlb_contact_map_column {
}
.dlb_contact_map_rows {
}

.dlb_text_point {
  .container {
    overflow: visible;
    position: relative;
    &:before {
      content: '';
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      top: 50%;
      background-color: #ccc;
    }
  }
  .dlb_text_point-title {
    position: relative;
  }
}

.dlb_contact-area {
  .col {
    border: 1px solid #555555;
    & + .col {
      border-left: none;
      @include media-breakpoint-down(md) {
        border-left: 1px solid #555555;
      }
    }
  }
  .dlb_contact-item {
  }
  .dlb_contact-title {
    position: relative;
    margin-bottom: 0;
    padding: 10px 10px 10px 80px;
    @include media-breakpoint-down(md) {
      padding: 6px 6px 6px 60px;
    }
  }
  .dlb_contact-icon {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 60px;
    @include media-breakpoint-down(md) {
      width: 40px;
    }
  }
  .dlb_contact-list {
    margin-bottom: 1.5rem;
    padding: 0px 0px 0px 80px;
    @include media-breakpoint-down(md) {
      padding-left: 60px;
    }
    li {
      border-bottom: 1px solid #ccc;
      a {
        display: block;
        padding: 0.5rem;
      }
    }
  }
  .dlb_contact-tel {
    margin-bottom: 1.5rem;
    padding-right: 1rem;
    .dlb_contact-icon {
      position: static;
      padding-right: 15px;
      width: inherit;
      text-align: right;
      img {
        width: 50px;
        @include media-breakpoint-down(md) {
          width: 30px;
        }
      }
    }
    .dlb_contact-number {
      font-size: 2.5rem;
      @include media-breakpoint-down(md) {
        font-size: 2rem;
      }
    }
  }
  .dlb_contact-box {
    background-position: right bottom;
    background-repeat: no-repeat;
  }
  .dlb_contact-text {
    position: relative;
    background-color: #ebf4f9;
    width: 50%;
    padding: 1.5rem;
    top: 10px;
    left: 1rem;
    @include media-breakpoint-down(md) {
      width: 35%;
      padding: 1rem;
      font-size: 12px;
      top: 0;
    }
    @include media-breakpoint-down(sm) {
      width: 40%;
      padding: 1rem;
      font-size: 12px;
      top: 1rem;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      right: -18px;
      margin-top: -1rem;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 18px 0 18px 18px;
      border-color: transparent transparent transparent #ebf4f9;
    }
  }
}

// dlb_ 追加分

// 線の入ったタイトルなどに使用
// | xxxxxxxxxxxxxxxxx
// -------------------
.dlb_title-sub-01 {
  position: relative;
  border-left: 4px solid #ccc;
  padding-left: 20px;
  padding-bottom: 4px;
  margin-bottom: 2.5rem;
  font-weight: bold;
  padding-top: 2px;
  @include media-breakpoint-down(sm) {
    margin-bottom: 2rem;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: -16px;
    left: 0;
    right: 0;
    display: block;
    height: 1px;
    background-color: #ddd;
  }
}

// 線の入ったタイトルなどに使用 2
// | xxxxxxxxxxxxxxxxx
.dlb_title-sub-02 {
  border-bottom: 1px solid #ccc;
  border-left: 8px solid #ccc;
  padding-left: 16px;
  padding-bottom: 0.25em;
  margin-bottom: 2rem;
  font-weight: bold;
}

// 先頭に細いボーダーのタイトル
.dlb_title-sub-03 {
  border-left: 4px solid #ccc;
  padding-left: 16px;
  font-weight: bold;
}

// 先頭に長方形のタイトル
.dlb_title-sub-04 {
  position: relative;
  font-weight: bold;
  padding-left: 1em;
  text-indent: -1em;
  &::before {
    content: "";
    display: inline-block;
    width: 0.5em;
    height: 0.75em;
    margin-right: 0.5em;
    background-color: #ddd;
  }
}

// 下ボーダーのタイトル
.dlb_title-sub-05 {
  position: relative;
  border-bottom: 1px solid #ccc;
  padding-bottom: 0.25em;
  font-weight: bold;
}

// 下に短い線のタイトル
.dlb_title-sub-06 {
  position: relative;
  font-weight: bold;
  padding-bottom: 0.5em;
  text-align: center;
  &::after {
    content: "";
    position: absolute;
    display: block;
    bottom: 0;
    left: 50%;
    width: 1em;
    height: 0.2em;
    min-height: 1px;
    margin-left: -0.5em;
    background-color: #ccc;
  }
}

// 塗りつぶしのタイトル
.dlb_title-sub-07 {
  position: relative;
  padding: 0.125em 0.25em;
  background-color: #ccc;
  font-weight: bold;
}

// パララックスする背景
.dlb_parallax-bg {
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 300px;
  margin-top: 3rem;
  margin-bottom: 3rem;
  @include media-breakpoint-down(md) {
    background-attachment: unset;
  }
  // iPad 横向き
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape){
    background-attachment: unset;
  }
}

// footer fixed
.dlb_footer-fixed {
  position: fixed;
  bottom: -92px;
  left: 0;
  z-index: 1000;
  transition: 0.3s ease;
  background-color: #fff;
  opacity: 0;
  &.scroll-in {
    bottom: 0;
    opacity: 1;
  }
  &.scroll-stop {
    // position: relative;
    position: fixed;
    opacity: 0;
    bottom: -92px;
  }
}
.share_top-link {
  position: fixed;
  bottom: 0px;
  right: 0;
  left: 0;
  z-index: 1000;
  .container {
    position: relative;
    margin-top: 0;
    margin-bottom: 0;
  }
  .top-link {
    position: absolute;
    top: -135px;
    right: 0;
    @include media-breakpoint-down(md) {
      top: -172px;
      right: 10px;
    }
    @include media-breakpoint-down(sm) {
      top: -60px;
      right: 10px;
    }
    .btn {
      width: 50px;
      height: 50px;
      border-radius: 0.25rem !important;
      a {
        display: block;
        width: 100%;
        height: 100%;
        padding: 8px 16px;
        overflow: hidden;
        &::before {
          content: '';
          position: relative;
          top: 12px;
          left: -3px;
          display: inline-block;
          width: 24px;
          height: 24px;
          border-top: 2px solid #fff;
          border-right: 2px solid #fff;
          transform: rotate(-45deg);
        }
      }
    }
  }
}


// エディタ用表示記述
// bm_sliderの画像表示崩れ対応
#dcms_inner_body,
.editor_message {
  & + #dcms_layoutPageBlockPasteArea {
    .bm_slider-item {
      max-width: 25%;
      float: left;
    }
  }
}
#tinymce {
  .bm_slider-item {
    max-width: 25%;
    float: left;
  }
  .js_open,
  .js_morelink {
    display: block;
  }
  .collapse:not(.show) {
    display: block;
  }
  .modal {
    position: static;
    display: block;
    width: auto;
    height: auto;
    overflow: unset;
  }
  .fade:not(.show) {
    opacity: 1;
  }
  .modal-dialog {
    pointer-events: painted;
  }
  .tab-pane {
    display: block;
  }
  .dlb_slide-1 {
    img {
      max-width: 25%;
    }
    .dlb_slide_1-siema {
      & > div,
      .img-fluid {
        display: inline;
      }
    }
  }
  // swiper
  .swiper-container {
    overflow: unset;
  }
  .swiper-wrapper {
    display: block;
  }
  .swiper-slide {
    display: inline;
    img {
      max-height: 200px;
      width: auto;
    }
  }
}
.dcms_editor {
  .modal {
    height: auto;
  }
  .dlb_slide-1 {
    img {
      max-width: 24.8%;
      margin-bottom: 2px;
    }
    .dlb_slide_1-siema {
      & > div,
      .img-fluid {
        display: inline;
      }
    }
  }
}

// swiper customize
// prev
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: -10px;
}
// next
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  right: -10px;
}
:root {
  --swiper-navigation-size: 24px;
  --swiper-navigation-color: #000;
  --swiper-theme-color: #000;
}